// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/live-axios';
import { dispatch } from '../index';

// types
import { DefaultRootStateProps } from 'types';
import { TSetMessage } from 'types/messages';
import { TGetUser } from 'types/user';

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['message'] = {
	error: null,
	messages: [],
};

const slice = createSlice({
	name: 'message',
	initialState,
	reducers: {
		hasError(state, action) {
			state.error = action.payload;
		},

		getMessagesSuccess(state, action) {
			state.messages = action.payload;
		},
	},
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------
export function getAllMessages(ref: string) {
	return async () => {
		try {
			const response = await axios.get('/message', {
				params: { ref },
			});
			dispatch(slice.actions.getMessagesSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
export async function createMessage(data: TSetMessage, receivers: TGetUser[]) {
	try {
		await axios.post('/message', { ...data, receivers });
	} catch (error) {
		dispatch(slice.actions.hasError(error));
	}
}
export async function updateMessage(id: number, data: Partial<TSetMessage>) {
	try {
		await axios.put(`/message/${id}`, data);
	} catch (error) {
		dispatch(slice.actions.hasError(error));
	}
}
