import * as React from 'react';

// material-ui
import {
	Box,
	FormControl,
	IconButton,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	SelectChangeEvent,
} from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { useDispatch, useSelector } from 'store';

// assets

import Loader from 'ui-component/Loader';
import { formApi } from 'store/slices/form';
import { Form, FormStateProps } from 'types/form';
import _l from 'utils/lang';
import { useNavigate, useParams } from 'react-router-dom';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Chip from 'ui-component/extended/Chip';
import TableResponsive from 'ui-component/costume/TableResponsive';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import { currentUser, permissions } from 'global';

// table sort

// table header options

const STATUS = {
	1: 'in process',
	2: 'approved',
	3: 'rejected',
};

const STATUS_COLOR = {
	1: 'primary',
	2: 'success',
	3: 'error',
};

type Data = {
	nr: number;
	firstName?: string;
	lastName?: string;
	email?: string;
	phone?: string;
	status: JSX.Element;
	action: JSX.Element;
};

// ==============================|| ORDER LIST ||============================== //
let timer: NodeJS.Timeout | undefined;

export default function ViewHookData() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const params = useParams() as { id: string };
	const [rows, setRows] = React.useState<Data[]>([]);
	const [hookDetails, setHookDetails] = React.useState<Form | undefined>(
		undefined
	);
	const { formData: hookData, form: hook } = useSelector(
		(state) => state.form
	) as FormStateProps;
	const [isLoading, setLoading] = React.useState(true);
	const [statusFilter, setStatusFilter] = React.useState<(string | number)[]>([
		1, 2, 3,
	]);

	React.useEffect(() => {
		if (!currentUser.permissions.includes(permissions['form submission: read']))
			navigate('/forms');
		dispatch(formApi.getFormData(params.id, statusFilter));
		dispatch(formApi.getForm(params.id));
	}, [dispatch]);
	React.useEffect(() => {
		setRows(
			hookData.map((data) => ({
				nr: data.row_number,
				firstName: data.firstName,
				lastName: data.lastName,
				email: data.email,
				phone: data.phone,
				status: (
					<Chip
						label={STATUS[data.status as keyof typeof STATUS]}
						size="small"
						chipcolor={STATUS_COLOR[data.status as keyof typeof STATUS_COLOR]}
					/>
				),
				action: (
					<Box sx={{ display: 'flex', justifyContent: 'center' }}>
						<IconButton
							color="primary"
							size="medium"
							onClick={() => {
								navigate(`/forms/${params.id}/${data.id}`);
							}}
						>
							<VisibilityTwoToneIcon sx={{ fontSize: '1.3rem' }} />
						</IconButton>
					</Box>
				),
			}))
		);
		if (hookData.length === 0) setLoading(false);
	}, [hookData]);
	React.useEffect(() => {
		setHookDetails(hook);
	}, [hook]);
	React.useEffect(() => {
		if (rows.length > 0) setLoading(false);
	}, [rows]);
	React.useEffect(() => {
		clearTimeout(timer);
		if (statusFilter.length > 0)
			timer = setTimeout(() => {
				dispatch(formApi.getFormData(params.id, statusFilter));
			}, 500);
	}, [statusFilter]);

	const ITEM_HEIGHT = 48;
	const ITEM_PADDING_TOP = 8;
	const MenuProps = {
		PaperProps: {
			style: {
				maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
				width: 250,
			},
		},
	};

	const handleStatusChange = (
		event: SelectChangeEvent<(string | number)[]>
	) => {
		const {
			target: { value },
		} = event;
		setStatusFilter(
			// On autofill we get a stringified value.
			typeof value === 'string' ? value.split(',') : value
		);
	};

	return (
		<>
			{isLoading ? (
				<Loader />
			) : (
				<MainCard
					title={<Title hook={hookDetails} no={rows.length} />}
					content={false}
				>
					{rows && (
						<TableResponsive
							headCells={[
								{
									id: 'nr',
									label: 'No.',
									pin: true,
								},
								{
									id: 'firstName',
									label: _l('first-name'),
									displayInCollapseSection: true,
								},
								{
									id: 'lastName',
									label: _l('last-name'),
									displayInCollapseSection: true,
								},
								{
									id: 'email',
									label: _l('email'),
									pin: true,
								},
								{
									id: 'phone',
									label: _l('phone'),
									displayInCollapseSection: true,
								},
								{
									id: 'status',
									label: _l('status'),
									pin: true,
								},
								{
									id: 'action',
									label: _l('action'),
									align: 'center',
									sortable: false,
									displayInCollapseSection: true,
								},
							]}
							bodyCells={rows}
							openDialogPermission={false}
							searchBy={['nr', 'firstName', 'lastName', 'email', 'phone']}
							filters={[
								<FormControl
									key={0}
									sx={{
										width: 200,
										marginRight: '10px',
										marginBottom: '10px',
									}}
								>
									<InputLabel id="demo-multiple-name-label">
										{_l('status')}
									</InputLabel>
									<Select
										labelId="demo-multiple-name-label"
										id="demo-multiple-name"
										multiple
										value={statusFilter}
										onChange={handleStatusChange}
										input={<OutlinedInput label="Name" />}
										MenuProps={MenuProps}
										size="small"
									>
										{[1, 2, 3].map((status) => (
											<MenuItem key={status} value={status}>
												{
													// @ts-ignore
													STATUS[status]
												}
											</MenuItem>
										))}
									</Select>
								</FormControl>,
							]}
						/>
					)}
				</MainCard>
			)}
		</>
	);
}

interface TitleProps {
	hook: Form | undefined;
	no: number;
}
function Title(props: TitleProps) {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
			}}
		>
			<Box
				width="100%"
				sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
			>
				<ButtonBack />
				{props.hook?.description +
					' #' +
					props.hook?.id +
					' (' +
					props.no +
					')'}
			</Box>
		</Box>
	);
}

const ButtonBack = () => {
	const navigate = useNavigate();
	return (
		<Box>
			<IconButton
				color="secondary"
				size="small"
				onClick={() => navigate('/forms')}
			>
				<ChevronLeftIcon sx={{ fontSize: '1.3rem' }} />
			</IconButton>
		</Box>
	);
};
