// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/live-axios';
import { dispatch } from '../index';

// types
import { DefaultRootStateProps } from 'types';
import { TGetSms, TSetSms } from 'types/sms';
import { alert } from 'utils/helpers/alert';
import _l from 'utils/lang';

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['sms'] = {
	error: null,
	allSms: [],
	sms: undefined,
};

const slice = createSlice({
	name: 'sms',
	initialState,
	reducers: {
		hasError(state, action) {
			state.error = action.payload;
		},

		getAllSmsSuccess(state, action) {
			state.allSms = action.payload;
		},

		getSmsSuccess(state, action) {
			state.sms = action.payload;
		},
	},
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export const smsApi = {
	getAll: () => async () => {
		try {
			const response = await axios.get('/notification/sms');
			dispatch(slice.actions.getAllSmsSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	},
	getById: (params: Pick<TGetSms, 'id'>) => async () => {
		try {
			const response = await axios.get(`/notification/sms/${params.id}`);
			dispatch(slice.actions.getSmsSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	},
	get update() {
		return async (id: number, data: TSetSms, options?: { sync: boolean }) => {
			try {
				await axios.put(`/notification/sms/${id}`, data);
				if (options?.sync === true) this.getAll()();
				alert.display(_l('sms-template-updated-successfully'));
			} catch (error) {
				if (options?.sync === true) dispatch(slice.actions.hasError(error));
			}
		};
	},
};
