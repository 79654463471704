// third-party
import { FormattedMessage } from 'react-intl';

// assets
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import SettingsIcon from '@mui/icons-material/Settings';
import EditNotificationsIcon from '@mui/icons-material/EditNotifications';
import DashboardIcon from '@mui/icons-material/Dashboard';
import { IconDashboard } from '@tabler/icons';
import { NavItemType } from 'types';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

// constant
const icons = {
	IconDashboard,
	SupervisorAccountIcon,
	SettingsIcon,
	DashboardIcon,
	EditNotificationsIcon,
	RequestQuoteIcon,
	AdminPanelSettingsIcon,
	InsertDriveFileIcon,
};

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const budgetRequest: NavItemType = {
	id: 'app-menu',
	title: <FormattedMessage id="app-menu" />,
	icon: icons.IconDashboard,
	type: 'group',
	children: [
		{
			id: 'dashboard',
			title: <FormattedMessage id="dashboard" />,
			type: 'item',
			url: '/dashboard',
			icon: icons.DashboardIcon,
			breadcrumbs: false,
		},
		{
			id: 'purchase',
			title: <FormattedMessage id="purchase" />,
			type: 'item',
			url: '/purchase',
			icon: icons.RequestQuoteIcon,
			breadcrumbs: false,
		},
		{
			id: 'forms',
			title: <FormattedMessage id="hook" />,
			type: 'item',
			url: '/forms',
			icon: icons.InsertDriveFileIcon,
			breadcrumbs: false,
		},
		{
			id: 'users',
			title: <FormattedMessage id="users" />,
			type: 'item',
			url: '/users',
			icon: icons.SupervisorAccountIcon,
			breadcrumbs: false,
		},
		{
			id: 'roles',
			title: <FormattedMessage id="roles" />,
			type: 'item',
			url: '/roles',
			icon: icons.AdminPanelSettingsIcon,
			breadcrumbs: false,
		},
		{
			id: 'notification',
			title: <FormattedMessage id="notification" />,
			type: 'item',
			url: '/notification',
			icon: icons.EditNotificationsIcon,
			breadcrumbs: false,
		},
		{
			id: 'settings',
			title: <FormattedMessage id="settings" />,
			type: 'item',
			url: '/settings',
			icon: icons.SettingsIcon,
			breadcrumbs: false,
		},
	],
};

export default budgetRequest;
