import * as React from 'react';

// material-ui
import { Box, IconButton } from '@mui/material';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import { useDispatch, useSelector } from 'store';

// assets
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';

import AddHook from './AddHook';
import EditHook from './EditHook';
import Loader from 'ui-component/Loader';
import { formApi } from 'store/slices/form';
import { Form, FormStateProps } from 'types/form';
import _l from 'utils/lang';
import { useNavigate } from 'react-router-dom';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import Chart from './Chart';
import TableResponsive from 'ui-component/costume/TableResponsive';
import { currentUser, permissions } from 'global';
import NotAuthorized from 'views/NotAuthorized';

// ==============================|| TABLE HEADER TOOLBAR ||============================== //

type HookData = {
	id: number;
	title: string;
	responsable: string;
	active: string;
	action: JSX.Element;
};

// ==============================|| ORDER LIST ||============================== //

export default function Hooks() {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [rows, setRows] = React.useState<Form[]>([]);
	const [data, setData] = React.useState<HookData[]>([]);
	const [selectedHook, setSelectedHook] = React.useState<Form | undefined>(
		undefined
	);
	const [openCreate, setOpenCreate] = React.useState(false);
	const [openEdit, setOpenEdit] = React.useState(false);
	const [openChart, setOpenChart] = React.useState(false);
	const { forms: hooks } = useSelector((state) => state.form) as FormStateProps;
	const [isLoading, setLoading] = React.useState(true);

	React.useEffect(() => {
		dispatch(formApi.getForms());
	}, [dispatch]);
	React.useEffect(() => {
		setRows(hooks);
		setData(
			hooks.map((hook) => ({
				id: hook.id,
				title: hook.description,
				responsable: hook.User.firstName + ' ' + hook.User.lastName,
				active: hook.active ? _l('yes') : _l('no'),
				action: (
					<Box sx={{ display: 'flex', justifyContent: 'center' }}>
						{currentUser.permissions.includes(
							permissions['form submission: read']
						) && (
							<IconButton
								color="primary"
								size="medium"
								onClick={() => {
									navigate(`/forms/${hook.id}`);
								}}
							>
								<VisibilityTwoToneIcon sx={{ fontSize: '1.3rem' }} />
							</IconButton>
						)}
						{currentUser.permissions.includes(permissions['form: edit']) && (
							<IconButton
								color="secondary"
								size="medium"
								onClick={handleOpenEdit(hook)}
							>
								<EditTwoToneIcon sx={{ fontSize: '1.3rem' }} />
							</IconButton>
						)}
						<IconButton
							color="secondary"
							size="medium"
							onClick={() => {
								setOpenChart(true);
								setSelectedHook(hook);
							}}
						>
							<DataUsageIcon sx={{ fontSize: '1.3rem' }} />
						</IconButton>
					</Box>
				),
			}))
		);
		if (hooks.length === 0) setLoading(false);
	}, [hooks]);
	React.useEffect(() => {
		if (rows.length > 0) setLoading(false);
	}, [rows]);

	const handleCloseDialog = () => {
		setOpenCreate(false);
	};

	const handleCloseEdit = () => {
		setOpenEdit(false);
	};

	const handleOpenEdit = (row: Form) => () => {
		setSelectedHook(row);
		setOpenEdit(true);
	};

	return (
		<>
			{currentUser.permissions.includes(permissions['form: read']) ? (
				isLoading ? (
					<Loader />
				) : (
					<MainCard title={_l('hook') + ` (${rows.length})`} content={false}>
						{data && (
							<TableResponsive
								headCells={[
									{
										id: 'id',
										label: 'ID',
										pin: true,
									},
									{
										id: 'title',
										label: _l('title'),
										pin: true,
									},
									{
										id: 'responsable',
										label: _l('responsible'),
										displayInCollapseSection: true,
									},
									{
										id: 'active',
										label: _l('active'),
										displayInCollapseSection: true,
									},
									{
										id: 'action',
										label: _l('action'),
										align: 'center',
										sortable: false,
										displayInCollapseSection: true,
									},
								]}
								bodyCells={data}
								handleOpenDialog={() => setOpenCreate(true)}
								openDialogPermission={currentUser.permissions.includes(
									permissions['form: create']
								)}
								searchBy={['id', 'title', 'responsable']}
							/>
						)}

						{openCreate && <AddHook handleCloseDialog={handleCloseDialog} />}
						{openEdit && (
							<EditHook
								handleCloseDialog={handleCloseEdit}
								hook={selectedHook!}
							/>
						)}
						{openChart && (
							<Chart
								handleClose={() => setOpenChart(false)}
								formId={selectedHook!.id}
							/>
						)}
					</MainCard>
				)
			) : (
				<NotAuthorized />
			)}
		</>
	);
}
