import * as React from 'react';
import {
	CardContent,
	Grid,
	TextField,
	Button,
	useMediaQuery,
	Box,
	Chip,
	FormControl,
	MenuItem,
	Select,
	FormHelperText,
	Typography,
} from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { printDate } from 'utils/helpers/printDate';
import { TGetMessage } from 'types/messages';
import { CommentHistory } from './History';
import { useDispatch } from 'store';
import { createMessage, getAllMessages } from 'store/slices/message';
import _l from 'utils/lang';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { TGetUser } from 'types/user';

interface FormFields {
	users: string[];
}

const validationSchema = yup.object({
	users: yup.array(yup.string()).min(1, 'Field required'),
});

interface Props {
	messages: TGetMessage[];
	target: string;
	sectionId: number;
	uuid: string;
	users: TGetUser[];
}
export function CommentSection(props: Props) {
	const dispatch = useDispatch();
	const [messageText, setMessageText] = React.useState('');

	const initialValues: FormFields = {
		users: [`Everyone`],
	};
	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async (_) => {
			if (_) {
				//
			}
		},
	});

	const handleSendMessage = async () => {
		await createMessage(
			{
				text: messageText.trim(),
				sectionId: props.sectionId,
				target: props.target,
				ref: props.uuid,
			},
			formik.values.users.includes('Everyone')
				? props.users
				: props.users.filter((user) =>
						formik.values.users.includes(`${user.firstName} ${user.lastName}`)
				  )
		);
		dispatch(getAllMessages(props.uuid));
		setMessageText('');
	};
	const lgBreakpoint = useMediaQuery('(min-width:1024px)', { noSsr: true });

	return (
		<MainCard title={_l('comments')} content={false}>
			<CardContent>
				<form onSubmit={formik.handleSubmit}>
					<Grid container spacing={1}>
						<Grid
							item
							xs={12}
							sx={{ marginBottom: 3, paddingLeft: '0px !important' }}
						>
							<Grid container spacing={1}>
								<Grid
									item
									xs={12}
									sx={{ display: 'flex', alignItems: 'center' }}
								>
									<Typography sx={{ mr: 1, whiteSpace: 'nowrap' }}>
										{_l('notify')}
									</Typography>
									<FormControl>
										<Select
											variant="standard"
											id="select-approval-user"
											name="users"
											multiple
											renderValue={(selected) => (
												<Box
													sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
												>
													{selected.map((value) => (
														<Chip key={value} label={value} />
													))}
												</Box>
											)}
											value={formik.values.users}
											onBlur={formik.handleBlur}
											error={
												formik.touched.users && Boolean(formik.errors.users)
											}
											onChange={(e) => {
												const { value } = e.target;

												if (Array.isArray(value) && value.length > 0) {
													if (value[value.length - 1] === 'Everyone')
														formik.setFieldValue('users', ['Everyone'], true);
													else
														formik.setFieldValue(
															'users',
															value.filter((val) => val !== 'Everyone'),
															true
														);
												} else
													formik.setFieldValue('users', ['Everyone'], true);
											}}
										>
											<MenuItem key={0} value={`Everyone`}>
												{`Everyone (${props.users.length})`}
											</MenuItem>
											{props.users.map((option) => (
												<MenuItem
													key={option.email}
													value={`${option.firstName} ${option.lastName}`}
												>
													{`${option.firstName} ${option.lastName}`}
												</MenuItem>
											))}
										</Select>
										{formik.errors.users && (
											<FormHelperText error>
												{formik.errors.users}
											</FormHelperText>
										)}
									</FormControl>
								</Grid>

								<Grid item xs={12}>
									<TextField
										id="comment-text"
										fullWidth
										multiline
										minRows={4}
										maxRows={12}
										placeholder={_l('type-message')}
										value={messageText}
										onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
											setMessageText(event?.target.value);
										}}
										inputProps={{
											style: {
												padding: lgBreakpoint ? '16px' : '6px',
											},
										}}
									/>
								</Grid>
								<Grid
									item
									xs={2}
									sx={{
										display: 'flex',
										justifyContent: 'flex-start',
										alignItems: 'center',
									}}
								>
									<Button
										variant="contained"
										onClick={handleSendMessage}
										disabled={!messageText}
									>
										{_l('send')}
									</Button>
								</Grid>
							</Grid>
						</Grid>

						{props.messages.map((message) => (
							<Grid
								key={message.id}
								item
								xs={12}
								sx={{
									paddingTop: '16px !important',
								}}
							>
								<CommentHistory
									username={
										message.Sender.firstName + ' ' + message.Sender.lastName
									}
									time={printDate(message.createdAt)}
									text={message.text}
									messageId={message.id}
									userEmail={message.Sender.email}
									target={props.target}
									sectionId={props.sectionId}
									uuid={props.uuid}
									edited={message.createdAt != message.updatedAt}
								/>
							</Grid>
						))}
					</Grid>
				</form>
			</CardContent>
		</MainCard>
	);
}
