import * as React from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { visuallyHidden } from '@mui/utils';
import _l from 'utils/lang';
import {
	Collapse,
	Fab,
	Grid,
	InputAdornment,
	Stack,
	TextField,
	Tooltip,
	useMediaQuery,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/AddTwoTone';

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
	order: Order,
	orderBy: Key
): (
	a: { [key in Key]: number | string },
	b: { [key in Key]: number | string }
) => number {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
	const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

export interface HeadCell<T> {
	id: keyof T;
	label: string;
	align?: 'right' | 'left' | 'inherit' | 'center' | 'justify';
	pin?: boolean;
	pinOnlyMobile?: boolean;
	displayInCollapseSection?: boolean;
	sortable?: boolean;
}

interface EnhancedTableProps<T> {
	onRequestSort: (event: React.MouseEvent<unknown>, property: keyof T) => void;
	order: Order;
	orderBy: keyof T;
	headCells: readonly HeadCell<T>[];
	dense: boolean;
}

function EnhancedTableHead<T>(props: EnhancedTableProps<T>) {
	const { order, orderBy, onRequestSort, headCells, dense } = props;
	const createSortHandler =
		(property: keyof T) => (event: React.MouseEvent<unknown>) => {
			onRequestSort(event, property);
		};

	return (
		<TableHead>
			<TableRow>
				{headCells.map(
					(headCell, index) =>
						((!dense && !headCell.pinOnlyMobile) || headCell.pin) && (
							<TableCell
								// @ts-ignore
								key={headCell.id}
								align={headCell.align ? headCell.align : 'left'}
								sortDirection={orderBy === headCell.id ? order : false}
								sx={{
									pl: index === 0 ? '15px' : '0px',
									whiteSpace: 'nowrap',
									// '& .MuiTableSortLabel-root': {
									// 	fontSize: dense ? '12px' : '',
									// },
								}}
							>
								{headCell.sortable === false ? (
									headCell.label
								) : (
									<TableSortLabel
										active={orderBy === headCell.id}
										direction={orderBy === headCell.id ? order : 'asc'}
										onClick={createSortHandler(headCell.id)}
									>
										{headCell.label}
										{orderBy === headCell.id ? (
											<Box component="span" sx={visuallyHidden}>
												{order === 'desc'
													? 'sorted descending'
													: 'sorted ascending'}
											</Box>
										) : null}
									</TableSortLabel>
								)}
							</TableCell>
						)
				)}
				{dense && <TableCell padding="none" size="small" />}
			</TableRow>
		</TableHead>
	);
}

// =============================================================
// ==========================| Table |==========================
// =============================================================
interface Props<T> {
	headCells: readonly HeadCell<T>[];
	bodyCells: T[];
	handleOpenDialog?: () => void;
	searchBy: (keyof T)[];
	filters?: JSX.Element[];
	handleFilterChanged?: (
		search: string,
		page: number,
		rowsPerPage: number
	) => void;
	initFilters?: { search: string; page: number; rowsPerPage: number };
	toolbar?: boolean;
	openDialogPermission?: boolean;
	resetPageOnChange?: any[];
}
export default function TableResponsive<
	T extends { [x: string | number | symbol]: string | number | JSX.Element }
>({
	headCells,
	bodyCells,
	handleOpenDialog,
	searchBy,
	filters,
	handleFilterChanged,
	initFilters,
	toolbar,
	openDialogPermission,
	resetPageOnChange,
}: Props<T>) {
	const [order, setOrder] = React.useState<Order>('desc');
	const [orderBy, setOrderBy] = React.useState<keyof T | ''>('');
	const [page, setPage] = React.useState(initFilters?.page || 0);
	const [rowsPerPage, setRowsPerPage] = React.useState(
		initFilters?.rowsPerPage || 10
	);
	const dense = !useMediaQuery('(min-width:500px)', { noSsr: true });
	const [search, setSearch] = React.useState<string>(initFilters?.search || '');
	const [rows, setRows] = React.useState<T[]>(bodyCells);
	const [_resetPageOnChange] = React.useState(resetPageOnChange);

	React.useEffect(() => {
		setRows(bodyCells);
	}, [bodyCells]);
	React.useEffect(() => {
		if (handleFilterChanged) handleFilterChanged(search, page, rowsPerPage);
	}, [search, page, rowsPerPage]);
	React.useEffect(() => {
		if (
			JSON.stringify(_resetPageOnChange) !== JSON.stringify(resetPageOnChange)
		)
			setPage(0);
	}, [resetPageOnChange]);

	const handleRequestSort = (
		event: React.MouseEvent<unknown>,
		property: keyof T
	) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleChangePage = (event: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (
		event: React.ChangeEvent<HTMLInputElement>
	) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	const visibleRows = React.useMemo(
		() =>
			// @ts-ignore
			stableSort(rows, getComparator(order, orderBy)).slice(
				page * rowsPerPage,
				page * rowsPerPage + rowsPerPage
			),
		[order, orderBy, page, rowsPerPage, rows]
	);

	const handleSearch = (
		event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | undefined
	) => {
		const newString = event?.target.value;
		setSearch(newString || '');
		setPage(0);

		if (newString) {
			const newRows = bodyCells.filter((row) => {
				let matches = true;
				let containsQuery = false;

				searchBy.forEach((property) => {
					const value: string | number | JSX.Element = row[property];

					if (
						value
							.toString()
							.toLowerCase()
							.includes(newString.toString().toLowerCase())
					) {
						containsQuery = true;
					}
				});

				if (!containsQuery) {
					matches = false;
				}
				return matches;
			});
			setRows(newRows);
		} else {
			setRows(bodyCells);
		}
	};

	return (
		<Box sx={{ width: '100%' }}>
			<Paper sx={{ width: '100%', mb: 2 }}>
				{toolbar !== false && (
					<Grid
						container
						justifyContent="space-between"
						alignItems="center"
						spacing={2}
						padding="24px"
					>
						<Grid item xs={12} sm={9}>
							<TextField
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<SearchIcon fontSize="small" />
										</InputAdornment>
									),
								}}
								onChange={handleSearch}
								placeholder={_l('search')}
								value={search}
								size="small"
								sx={{ marginRight: '10px', marginBottom: '10px' }}
							/>

							{filters}
						</Grid>
						{openDialogPermission && (
							<Grid item xs={12} sm={3} sx={{ textAlign: 'right' }}>
								<Tooltip title={_l('add-purchase')}>
									<Fab
										color="primary"
										size="small"
										onClick={handleOpenDialog}
										sx={{
											boxShadow: 'none',
											ml: 1,
											width: 32,
											height: 32,
											minHeight: 32,
										}}
									>
										<AddIcon fontSize="small" />
									</Fab>
								</Tooltip>
							</Grid>
						)}
					</Grid>
				)}
				<TableContainer>
					<Table
						sx={{ minWidth: 100 }}
						aria-labelledby="collapsible table"
						size={dense ? 'small' : 'medium'}
					>
						<EnhancedTableHead
							order={order}
							orderBy={orderBy}
							onRequestSort={handleRequestSort}
							headCells={headCells}
							dense={dense}
						/>
						<TableBody>
							{!dense &&
								visibleRows.map((row) => {
									return (
										<TableRow
											hover
											role="checkbox"
											tabIndex={-1}
											key={row.name}
											sx={{ cursor: 'pointer' }}
										>
											{Object.keys(row).map((key, index) => (
												<TableCell
													key={key}
													sx={{
														pl: index === 0 ? '15px' : '0px',
														whiteSpace: 'nowrap',
													}}
												>
													{row[key]}
												</TableCell>
											))}
										</TableRow>
									);
								})}

							{dense &&
								headCells?.length > 0 &&
								visibleRows.map((row) => (
									<CollapseRows row={row} headCells={headCells} />
								))}

							{emptyRows > 0 && (
								<TableRow
									style={{
										height: (dense ? 33 : 53) * emptyRows,
									}}
								>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>

				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={rows.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
					labelRowsPerPage={_l('rows-per-page')}
					sx={
						dense
							? {
									'& .MuiInputBase-root': {
										marginLeft: '0px',
										marginRight: '8px',
									},
									'& .MuiTablePagination-actions': {
										marginLeft: '0px',
									},
									'& .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows':
										{
											fontSize: '11px',
										},
							  }
							: {}
					}
				/>
			</Paper>
		</Box>
	);
}

function CollapseRows<
	T extends { [x: string]: string | number | JSX.Element }
>({
	headCells,
	row,
}: {
	headCells: readonly HeadCell<T>[];
	row: { [key in keyof T]: string | number };
}) {
	const [open, setOpen] = React.useState(false);
	const pinned: string[] = [];
	const unpinned: string[] = [];

	headCells.forEach((cell) => {
		if (cell.pin || cell.pinOnlyMobile) pinned.push(cell.id as string);
		else if (cell.displayInCollapseSection) unpinned.push(cell.id as string);
	});

	return (
		<React.Fragment key={0}>
			<TableRow onClick={() => setOpen(!open)}>
				{pinned.map((key, index) => (
					<TableCell
						key={key}
						sx={{
							pl: index === 0 ? '15px' : '0px',
						}}
					>
						{row[key]}
					</TableCell>
				))}
				<TableCell padding="none">
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
						}}
					>
						{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
					</Box>
				</TableCell>
			</TableRow>

			<TableRow>
				<TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
					<Collapse in={open} timeout="auto" unmountOnExit>
						<Grid container spacing={0} sx={{ pt: 1 }}>
							{unpinned.map((key) => (
								<Grid item xs={12} key={key}>
									<Stack
										direction="row"
										spacing={1}
										sx={{ display: 'flex', alignItems: 'center' }}
									>
										<Typography
											variant="subtitle1"
											sx={{ whiteSpace: 'nowrap' }}
										>
											{key} :
										</Typography>
										<Typography
											variant="body2"
											display="flex"
											alignItems="center"
										>
											{row[key]}
										</Typography>
									</Stack>
								</Grid>
							))}
						</Grid>
					</Collapse>
				</TableCell>
			</TableRow>
		</React.Fragment>
	);
}
