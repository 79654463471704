import ReactGA from 'react-ga4';
ReactGA.initialize(GA_ID);

import { createRoot } from 'react-dom/client';

// third party
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { SnackbarProvider } from 'notistack';

// load mock apis
import '_mockApis';

// project imports
import App from 'App';
import { BASE_PATH, GA_ID } from 'config';
import { store, persister } from 'store';
import * as serviceWorker from 'serviceWorker';
import reportWebVitals from 'reportWebVitals';
import { ConfigProvider } from 'contexts/ConfigContext';

// style + assets
import 'assets/scss/style.scss';
import axios from 'utils/live-axios';
import { permissions, setPermissions } from 'global';
import './io';
// ==============================|| REACT DOM RENDER  ||============================== //

let titleTimer: NodeJS.Timeout | undefined;
axios
	.get('/config/name/TITLE')
	.then((response) => {
		clearTimeout(titleTimer);
		titleTimer = setTimeout(
			() => (document.title = `${response.data.value}`),
			1000
		);
	})
	.catch(() => (document.title = 'error'));
axios.get('/action').then((response) =>
	response.data.map(
		(permission: { name: keyof typeof permissions; id: number }) => {
			setPermissions({ key: permission.name, val: permission.id });
		}
	)
);

const container = document.getElementById('root');
if (container) container.style.overflow = 'hidden';
const root = createRoot(container!);
root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={persister}>
			<ConfigProvider>
				<BrowserRouter basename={BASE_PATH}>
					<SnackbarProvider maxSnack={3}>
						<App />
					</SnackbarProvider>
				</BrowserRouter>
			</ConfigProvider>
		</PersistGate>
	</Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
