// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/live-axios';
import { dispatch } from '../index';

// types
import { DefaultRootStateProps } from 'types';
import { TGetEmail, TSetEmail } from 'types/email';
import { alert } from 'utils/helpers/alert';
import _l from 'utils/lang';

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['email'] = {
	error: null,
	emails: [],
	email: undefined,
};

const slice = createSlice({
	name: 'email',
	initialState,
	reducers: {
		hasError(state, action) {
			state.error = action.payload;
		},

		getEmailsSuccess(state, action) {
			state.emails = action.payload;
		},

		getEmailSuccess(state, action) {
			state.email = action.payload;
		},
	},
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export const emailApi = {
	getAll: () => async () => {
		try {
			const response = await axios.get('/notification/email');
			dispatch(slice.actions.getEmailsSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	},
	getById: (params: Pick<TGetEmail, 'id'>) => async () => {
		try {
			const response = await axios.get(`/notification/email/${params.id}`);
			dispatch(slice.actions.getEmailSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	},
	get update() {
		return async (id: number, data: TSetEmail, options?: { sync: boolean }) => {
			try {
				await axios.put(`/notification/email/${id}`, data);
				if (options?.sync === true) this.getAll()();
				alert.display(_l('email-template-updated-successfully'));
			} catch (error) {
				if (options?.sync === true) dispatch(slice.actions.hasError(error));
			}
		};
	},
};
