import { Navigate, useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import LoginRoutes from './LoginRoutes';

// ==============================|| ROUTING RENDER ||============================== //
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
import Page_404 from 'views/pages/404';

export default function ThemeRoutes() {
	useEffect(() => {
		ReactGA.send({
			hitType: 'pageview',
			page: window.location.pathname,
			title: window.location.pathname,
		});
		console.log(window.location.pathname);

		if (!window.location.pathname.startsWith('/purchase')) {
			const purchaseFilter = JSON.parse(
				localStorage.getItem('purchase-filter') || '{}'
			);
			purchaseFilter.page = 0;

			localStorage.setItem('purchase-filter', JSON.stringify(purchaseFilter));
		}
	}, [window.location.pathname]);

	return useRoutes([
		{ path: '/', element: <Navigate to="/login" /> },
		LoginRoutes,
		MainRoutes,
		{ path: '/*', element: <Page_404 /> },
	]);
}
