// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/live-axios';
import { dispatch } from '../index';

// types
import { DefaultRootStateProps } from 'types';
import { alert } from 'utils/helpers/alert';
import _l from 'utils/lang';
import { SetSendSms } from 'types/sendSms';

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['sendSms'] = {
	error: null,
	sms: [],
};

const slice = createSlice({
	name: 'sendSms',
	initialState,
	reducers: {
		hasError(state, action) {
			state.error = action.payload;
		},

		getSmsSuccess(state, action) {
			state.sms = action.payload;
		},
	},
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export const sendSmsApi = {
	getAll: (ref: string) => async () => {
		try {
			const response = await axios.get(`/sms/${ref}`);
			dispatch(slice.actions.getSmsSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	},
	get create() {
		return async (ref: string, data: SetSendSms) => {
			try {
				await axios.post(`/sms/${ref}`, data);
				alert.display(_l('message-sended-successfully'));
			} catch (error) {
				dispatch(slice.actions.hasError(error));
			}
		};
	},
};
