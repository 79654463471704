// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/live-axios';
import { dispatch } from '../index';

// types
import { DefaultRootStateProps } from 'types';
import { TCreatePurchase } from 'types/purchase';
import { TEnumPurchaseStatus } from 'types/purchaseStatus';
import { TSetPurchaseRule } from 'types/purchaseRule';
import { alert } from 'utils/helpers/alert';
import _l from 'utils/lang';
import { TGetPurchaseTransaction } from 'types/purchaseTransaction';

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['purchase'] = {
	error: null,
	purchases: [],
	purchase: undefined,
	rules: [],
	paymentType: [],
	transactions: [],
	messagesReceivers: [],
};

const slice = createSlice({
	name: 'purchase',
	initialState,
	reducers: {
		hasError(state, action) {
			state.error = action.payload;
		},

		getPurchasesSuccess(state, action) {
			state.purchases = action.payload;
		},

		getPurchaseSuccess(state, action) {
			state.purchase = action.payload;
		},

		getPurchaseRulesSuccess(state, action) {
			state.rules = action.payload;
		},

		getPurchasePaymentTypeSuccess(state, action) {
			state.paymentType = action.payload;
		},

		getPurchaseTransactionsSuccess(state, action) {
			state.transactions = action.payload;
		},

		getMessageReceiversSuccess(state, action) {
			state.messagesReceivers = action.payload;
		},
	},
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getAllPurchases(statusId: (string | number)[]) {
	return async () => {
		try {
			const response = await axios.get('/purchase', {
				params: { statusId },
			});
			dispatch(slice.actions.getPurchasesSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
export function getReceivers(purchaseId: number) {
	return async () => {
		try {
			const response = await axios.get('/purchase/receivers/' + purchaseId);
			dispatch(slice.actions.getMessageReceiversSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
export function getTransactions(id: number) {
	return async () => {
		try {
			const response = await axios.get(`/purchase/${id}/transaction`);
			dispatch(slice.actions.getPurchaseTransactionsSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
export function getPurchaseById(query: { id: number }) {
	return async () => {
		try {
			const response = await axios.get(`/purchase/${query.id}`);
			dispatch(slice.actions.getPurchaseSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
export async function createPurchase(data: TCreatePurchase) {
	try {
		await axios.post('/purchase', data);
		alert.display(_l('purchase-created-successfully'));
	} catch (error) {
		dispatch(slice.actions.hasError(error));
	}
}
export async function updatePurchase(id: number, purchase: TCreatePurchase) {
	try {
		await axios.put(`/purchase/${id}`, { purchase });
		alert.display(_l('purchase-updated-successfully'));
	} catch (error) {
		dispatch(slice.actions.hasError(error));
	}
}
export async function updateApprovals(id: number, approvalsId: number[]) {
	try {
		await axios.put(`/purchase/${id}`, { approvalsId });
		alert.display(_l('purchase-approval-updated-successfully'));
	} catch (error) {
		dispatch(slice.actions.hasError(error));
	}
}
export async function approvePurchase(
	id: number,
	data: {
		statusId: TEnumPurchaseStatus;
		amountApproved?: number;
		currencyIdApproved?: number;
	}
) {
	try {
		await axios.post(`/purchase/${id}/handleStatus`, data);
		alert.display(_l('purchase-status-updated-successfully'));
	} catch (error) {
		dispatch(slice.actions.hasError(error));
	}
}
export async function createTransaction(
	data: Pick<
		TGetPurchaseTransaction,
		'amount' | 'currencyId' | 'details' | 'paymentTypeId' | 'purchaseId'
	>
) {
	try {
		await axios.post(`/purchase/paid`, data);
		alert.display(_l('purchase-paid'));
	} catch (error) {
		dispatch(slice.actions.hasError(error));
	}
}

export async function closePurchase(id: number) {
	try {
		await axios.put(`/purchase/${id}/close`);
		alert.display(_l('purchase-closed'));
	} catch (error) {
		dispatch(slice.actions.hasError(error));
	}
}
export async function stopRecurrencePurchase(id: number) {
	try {
		await axios.post(`/purchase/${id}/stopRecurrence`);
		alert.display(_l('purchase-marked-stop'));
	} catch (error) {
		dispatch(slice.actions.hasError(error));
	}
}

export function getAllPurchasePaymentType() {
	return async () => {
		try {
			const response = await axios.get('/purchase/paymentType');
			dispatch(slice.actions.getPurchasePaymentTypeSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}

export function getAllPurchaseRules() {
	return async () => {
		try {
			const response = await axios.get('/purchase/rule');
			dispatch(slice.actions.getPurchaseRulesSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	};
}
export async function createPurchaseRule(data: TSetPurchaseRule) {
	try {
		await axios.post(`/purchase/rule`, data);
		alert.display(_l('purchase-rule-created-successfully'));
	} catch (error) {
		dispatch(slice.actions.hasError(error));
	}
}
export async function updatePurchaseRule(id: number, data: TSetPurchaseRule) {
	try {
		await axios.put(`/purchase/rule/${id}`, data);
		alert.display(_l('purchase-rule-updated-successfully'));
	} catch (error) {
		dispatch(slice.actions.hasError(error));
	}
}
