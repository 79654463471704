import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { useNavigate } from 'react-router-dom';

export default function Page_404() {
	const navigate = useNavigate();

	return (
		<Box
			sx={{
				height: '100vh',
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				justifyContent: 'center',
			}}
		>
			<Typography variant="h1">404</Typography>
			<Typography variant="h2">Page not found</Typography>
			<Button sx={{ mt: '10px' }} onClick={() => navigate('/dashboard')}>
				Go to dashboard {'-->'}
			</Button>
		</Box>
	);
}
