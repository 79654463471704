import * as React from 'react';
import { forwardRef } from 'react';

// material-ui
import {
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	Slide,
	SlideProps,
	Typography,
	useTheme,
} from '@mui/material';

import DonutChart from 'react-donut-chart';

// project imports
import { gridSpacing } from 'store/constant';

// assets
import _l from 'utils/lang';
import { BE_HOST } from 'config';
import axiosServices from 'utils/live-axios';
import { Box } from '@mui/system';

// animation
const Transition = forwardRef((props: SlideProps, ref) => (
	<Slide direction="left" ref={ref} {...props} />
));

const STATUS: { [x: number]: string } = {
	1: 'in process',
	2: 'approved',
	3: 'rejected',
};

export default function ChartStatus({
	formId,
	handleClose,
}: {
	formId: number;
	handleClose: () => void;
}) {
	const theme = useTheme();
	const STATUS_COLOR: { [x: number]: string } = {
		1: theme.palette.primary.main,
		2: theme.palette.success.main,
		3: theme.palette.error.main,
	};
	const [data, setData] = React.useState<{ status: number; count: number }[]>(
		[]
	);

	React.useEffect(() => {
		axiosServices.get(`/form/${formId}/status`).then((response) => {
			setData(
				[1, 2, 3].map(
					(status) =>
						response.data.find(
							(data: { status: number; count: number }) =>
								data.status === status
						) || { status, count: 0 }
				)
			);
		});
	}, []);

	return (
		<Dialog
			open
			TransitionComponent={Transition}
			keepMounted
			onClose={handleClose}
			sx={{
				'&>div:nth-of-type(3)': {
					justifyContent: 'flex-end',
					'&>div': {
						m: 0,
						borderRadius: '0px',
						width: 450,
						maxHeight: '100%',
					},
				},
			}}
		>
			<DialogTitle>Diagram by status</DialogTitle>

			<DialogContent>
				<Grid container spacing={gridSpacing} sx={{ mt: 0.25 }}>
					<Grid
						item
						xs={12}
						display="flex"
						flexDirection="column"
						alignItems="center"
					>
						<DonutChart
							data={data.map((status) => ({
								label: STATUS[status.status],
								value: status.count,
							}))}
							width={280}
							height={280}
							colors={[
								theme.palette.primary.main,
								theme.palette.success.main,
								theme.palette.error.main,
							]}
							legend={false}
						/>
						<Box>
							{data.map((status) => (
								<Box sx={{ display: 'flex', alignItems: 'center' }}>
									<Box
										width={10}
										height={10}
										bgcolor={STATUS_COLOR[status.status]}
										mr="5px"
									/>
									<Typography>
										{STATUS[status.status]} - {status.count}
									</Typography>
								</Box>
							))}
						</Box>
					</Grid>
				</Grid>
			</DialogContent>
		</Dialog>
	);
}
