import { dispatch } from 'store';
import { getAllMessages } from 'store/slices/message';
import { socket } from './global';

socket.on('connect', () => {
	console.log(socket.id);
});

socket.on('disconnect', () => {
	console.log(socket.id);
});

socket.on(
	'message-created',
	(data: { sectionId: number; target: string; ref: string }) => {
		if (
			(data.sectionId === 1 &&
				window.location.pathname === '/purchase/' + data.target) ||
			(data.sectionId === 9 && window.location.pathname === data.target)
		)
			dispatch(getAllMessages(data.ref));
	}
);
socket.on(
	'message-updated',
	(data: { sectionId: number; target: string; ref: string }) => {
		if (
			(data.sectionId === 1 &&
				window.location.pathname === '/purchase/' + data.target) ||
			(data.sectionId === 9 && window.location.pathname === data.target)
		)
			dispatch(getAllMessages(data.ref));
	}
);
