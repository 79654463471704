import * as React from 'react';
import { forwardRef } from 'react';

// material-ui
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControlLabel,
	Grid,
	MenuItem,
	Slide,
	SlideProps,
	Stack,
	Switch,
	TextField,
	Typography,
} from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useDispatch, useSelector } from 'store';

// assets
import * as yup from 'yup';
import { useFormik } from 'formik';
import _l from 'utils/lang';
import { Form, SetForm } from 'types/form';
import { formApi } from 'store/slices/form';
import { BE_HOST } from 'config';
import { userApi } from 'store/slices/user';
import { TGetUser } from 'types/user';

interface ProductAddProps {
	handleCloseDialog: (e?: any) => void;
	hook: Form;
}
interface FormFields {
	description: string;
	active: boolean;
	userId: string;
	message: string;
}
const validationSchema = yup.object({
	description: yup.string().required(_l('field-required')),
	active: yup.boolean(),
	userId: yup.string().required(_l('field-required')),
	message: yup.string(),
});

// animation
const Transition = forwardRef((props: SlideProps, ref) => (
	<Slide direction="left" ref={ref} {...props} />
));

export default function EditHook({ handleCloseDialog, hook }: ProductAddProps) {
	const dispatch = useDispatch();
	const [user, setUser] = React.useState<TGetUser[]>([]);
	const { activeUsers } = useSelector((state) => state.user);

	const initialValues: FormFields = {
		description: hook.description,
		active: hook.active ?? false,
		userId: String(hook.userId),
		message: hook.message ?? '',
	};
	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async (_) => {
			if (_) {
				const data: SetForm = {
					description: _.description,
					active: _.active,
					userId: Number(_.userId),
					message: _.message,
				};
				await formApi.update(hook.id, data);
				await dispatch(formApi.getForms());
				handleCloseDialog();
			}
		},
	});

	React.useEffect(() => {
		dispatch(userApi.getAll({ status: 'active' }));
	}, [dispatch]);
	React.useEffect(() => {
		setUser(activeUsers);
	}, [activeUsers]);

	return (
		<Dialog
			open
			TransitionComponent={Transition}
			keepMounted
			onClose={handleCloseDialog}
			sx={{
				'&>div:nth-of-type(3)': {
					justifyContent: 'flex-end',
					'&>div': {
						m: 0,
						borderRadius: '0px',
						width: 450,
						maxHeight: '100%',
					},
				},
			}}
		>
			<DialogTitle>
				{_l('form')} #{hook.id}
			</DialogTitle>

			<form onSubmit={formik.handleSubmit}>
				<DialogContent>
					<Grid container spacing={gridSpacing} sx={{ mt: 0.25 }}>
						<Grid item xs={12}>
							<Stack direction="row" spacing={1}>
								<Typography variant="subtitle1">Link:</Typography>
								<Typography
									variant="body2"
									display="flex"
									fontSize={12}
									alignItems="center"
								>
									{BE_HOST}/form/{hook.uuid}/data
								</Typography>
							</Stack>
						</Grid>
						<Grid item xs={12}>
							<TextField
								label={_l('enter-title')}
								id="description"
								fullWidth
								value={formik.values.description}
								onBlur={formik.handleBlur}
								error={
									formik.touched.description &&
									Boolean(formik.errors.description)
								}
								helperText={
									formik.touched.description && formik.errors.description
								}
								onChange={formik.handleChange}
							/>
						</Grid>

						<Grid item xs={12}>
							<TextField
								id="userId"
								name="userId"
								select
								label={_l('select-responsible')}
								fullWidth
								value={formik.values.userId}
								onBlur={formik.handleBlur}
								error={formik.touched.userId && Boolean(formik.errors.userId)}
								helperText={formik.touched.userId && formik.errors.userId}
								onChange={formik.handleChange}
							>
								{user.map((usr) => (
									<MenuItem key={String(usr.id)} value={usr.id}>
										{usr.firstName} {usr.lastName}
									</MenuItem>
								))}
							</TextField>
						</Grid>

						<Grid item xs={12}>
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<Typography>{_l('dynamic-data')}</Typography>
								</Grid>
								<Grid item xs={12} ml={1}>
									<Text label={_l('first-name')} value="{firstName}" />
									<Text label={_l('last-name')} value="{lastName}" />
								</Grid>
							</Grid>

							<TextField
								sx={{ mt: 1 }}
								id="message"
								fullWidth
								multiline
								minRows={4}
								maxRows={12}
								label={_l('type-message')}
								value={formik.values.message}
								onBlur={formik.handleBlur}
								error={formik.touched.message && Boolean(formik.errors.message)}
								helperText={formik.touched.message && formik.errors.message}
								onChange={formik.handleChange}
							/>
							<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
								{Math.ceil(formik.values.message.length / 160) > 1
									? `sms: ${Math.ceil(formik.values.message.length / 160)}`
									: ''}{' '}
								{formik.values.message.length % 160 ||
									(formik.values.message.length > 0 && 160) ||
									0}
								/160
							</Box>
						</Grid>

						<Grid item xs={12}>
							<FormControlLabel
								control={
									<Switch
										name="active"
										checked={formik.values.active}
										onBlur={formik.handleBlur}
										onChange={formik.handleChange}
									/>
								}
								label={_l('active')}
							/>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<AnimateButton>
						<Button
							disabled={formik.isSubmitting}
							variant="contained"
							type="submit"
						>
							Save
						</Button>
					</AnimateButton>
					<Button variant="text" color="error" onClick={handleCloseDialog}>
						Close
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
}

interface TextProps {
	label: string;
	value: string | number | undefined;
}
const Text = ({ label, value }: TextProps) => (
	<Stack direction="row" spacing={1}>
		<Typography variant="subtitle1">{label} :</Typography>
		<Typography variant="body2" display="flex" alignItems="center">
			{value}
		</Typography>
	</Stack>
);
