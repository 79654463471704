import * as React from 'react';
import { forwardRef, useState } from 'react';

// material-ui
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	MenuItem,
	Slide,
	SlideProps,
	Box,
	Chip,
	FormControl,
	InputLabel,
	OutlinedInput,
	Select,
	FormHelperText,
} from '@mui/material';

// project imports
import { gridSpacing } from 'store/constant';
import AnimateButton from 'ui-component/extended/AnimateButton';
import { useDispatch, useSelector } from 'store';

// assets
import { userApi } from 'store/slices/user';
import { TGetUser } from 'types/user';
import * as yup from 'yup';
import { useFormik } from 'formik';
import _l from 'utils/lang';
import { formApi } from 'store/slices/form';

interface SetApprovalsAddProps {
	handleCloseDialog: (e?: any) => void;
	formDataRef: string;
	formDataId: string;
}
interface FormFields {
	approvals: string[];
}

const validationSchema = yup.object({
	approvals: yup.array(yup.string()).min(1, 'Field required'),
});

// animation
const Transition = forwardRef((props: SlideProps, ref) => (
	<Slide direction="left" ref={ref} {...props} />
));

export default function SetApprovals({
	handleCloseDialog,
	formDataRef,
	formDataId,
}: SetApprovalsAddProps) {
	const dispatch = useDispatch();
	const [user, setUser] = useState<TGetUser[]>([]);
	const [defaultApprovals, setDefaultApprovals] = React.useState<string[]>([]);
	const { activeUsers } = useSelector((state) => state.user);
	const { approvals } = useSelector((state) => state.form);

	const initialValues: FormFields = {
		approvals: [],
	};
	const formik = useFormik({
		initialValues,
		validationSchema,
		onSubmit: async (_) => {
			if (_) {
				const position: { [x: string]: number } = {};
				_.approvals.forEach((username, index) => {
					position[username] = index;
				});
				const approvalsId = user
					.filter((ob) =>
						_.approvals.includes(`${ob.firstName} ${ob.lastName}`)
					)
					.sort(
						(a, b) =>
							position[`${a.firstName} ${a.lastName}`] -
							position[`${b.firstName} ${b.lastName}`]
					)
					.map((ob) => ob.id);
				await formApi.setApprovals({
					ref: formDataRef,
					approvals: approvalsId,
				});
				dispatch(formApi.getFormApprovals(formDataRef));
				dispatch(formApi.getFormDataById(formDataId));
				dispatch(formApi.getReceivers(formDataRef));
				handleCloseDialog();
			}
		},
	});

	React.useEffect(() => {
		dispatch(userApi.getAll({ status: 'active' }));
		dispatch(formApi.getFormApprovals(formDataRef));
	}, [dispatch]);
	React.useEffect(() => {
		setUser(activeUsers);
	}, [activeUsers]);
	React.useEffect(() => {
		if (approvals) {
			const list = approvals.map(
				(approval) => `${approval.User.firstName} ${approval.User.lastName}`
			);
			setDefaultApprovals(list);
			formik.values.approvals = list;
		}
	}, [approvals]);

	return (
		<Dialog
			open
			TransitionComponent={Transition}
			keepMounted
			onClose={handleCloseDialog}
			sx={{
				'&>div:nth-of-type(3)': {
					justifyContent: 'flex-end',
					'&>div': {
						m: 0,
						borderRadius: '0px',
						width: 450,
						maxHeight: '100%',
					},
				},
			}}
		>
			<DialogTitle>{_l('form-approvals')}</DialogTitle>
			<form onSubmit={formik.handleSubmit}>
				<DialogContent>
					<Grid container spacing={gridSpacing} sx={{ mt: 0.25 }}>
						<Grid item xs={12}>
							<FormControl fullWidth>
								<InputLabel
									required
									error={
										formik.touched.approvals && Boolean(formik.errors.approvals)
									}
									id="approval-user"
								>
									{_l('select-approvals')}
								</InputLabel>
								<Select
									labelId="approval-user"
									id="select-approval-user"
									name="approvals"
									multiple
									input={
										<OutlinedInput
											id="select-multiple-chip"
											label={_l('select-approvals')}
										/>
									}
									renderValue={(selected) => (
										<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
											{selected.map((value) => (
												<Chip key={value} label={value} />
											))}
										</Box>
									)}
									value={formik.values.approvals}
									onBlur={formik.handleBlur}
									error={
										formik.touched.approvals && Boolean(formik.errors.approvals)
									}
									onChange={formik.handleChange}
								>
									{user.map((option) => (
										<MenuItem
											key={option.email}
											value={`${option.firstName} ${option.lastName}`}
										>
											{`${option.firstName} ${option.lastName}`}
										</MenuItem>
									))}
								</Select>
								{formik.errors.approvals && (
									<FormHelperText error>
										{formik.errors.approvals}
									</FormHelperText>
								)}
							</FormControl>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<AnimateButton>
						<Button
							variant="contained"
							type="submit"
							disabled={
								formik.isSubmitting ||
								JSON.stringify(defaultApprovals) ===
									JSON.stringify(formik.values.approvals)
							}
						>
							{_l('save')}
						</Button>
					</AnimateButton>
					<Button variant="text" color="error" onClick={handleCloseDialog}>
						{_l('close')}
					</Button>
				</DialogActions>
			</form>
		</Dialog>
	);
}
