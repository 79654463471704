export const VERSION = {
	label: 'v1.4.1',
	value: '1.4.1',
};

const LAYOUT_CONST = {
	VERTICAL_LAYOUT: 'vertical',
	HORIZONTAL_LAYOUT: 'horizontal',
	DEFAULT_DRAWER: 'default',
	MINI_DRAWER: 'mini-drawer',
};
export default LAYOUT_CONST;
