import { Button, Popover, SxProps } from '@mui/material';
import React from 'react';
import AnimateButton from 'ui-component/extended/AnimateButton';

interface ButtonConfirmProps {
	id: number;
	color: 'success' | 'error' | 'warning';
	label: string;
	callback: () => {};
	size?: 'small' | 'medium';
	sx?: SxProps;
}
export default function ButtonConfirm(props: ButtonConfirmProps) {
	const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
		null
	);
	const open = Boolean(anchorEl);

	const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleConfirm = () => {
		setAnchorEl(null);
		props.callback();
	};
	return (
		<AnimateButton>
			<Button
				variant="outlined"
				color={props.color}
				onClick={handleClick}
				aria-describedby={`action-${props.id}`}
				size={props.size || 'medium'}
				sx={{ ...props.sx, whiteSpace: 'nowrap' }}
			>
				{props.label}
			</Button>
			<Popover
				id={`action-${props.id}`}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center',
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'center',
				}}
			>
				<Button variant="contained" color="primary" onClick={handleConfirm}>
					Confirm
				</Button>
			</Popover>
		</AnimateButton>
	);
}
