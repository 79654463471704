import { BE_HOST } from 'config';
import { io } from 'socket.io-client';
export const socket = io(BE_HOST);

export const permissions = {
	'dashboard: read': 0,
	'purchase: read all': 0,
	'purchase: read own': 0,
	'purchase: create own': 0,
	'purchase: update own': 0,
	'purchase: payment': 0,
	'user: read': 0,
	'user: create': 0,
	'user: update': 0,
	'role: read': 0,
	'role: create': 0,
	'role: update': 0,
	'role: delete': 0,
	'settings finances: read': 0,
	'settings finances: create': 0,
	'settings finances: update': 0,
	'settings finances: delete': 0,
	'settings finances: change main currency': 0,
	'settings variables: read': 0,
	'settings variables: update': 0,
	'settings rules: read': 0,
	'settings rules: create': 0,
	'settings rules: update': 0,
	'notification: read': 0,
	'notification: update': 0,
	'form: read': 0,
	'form: edit': 0,
	'form: create': 0,
	'form submission: read': 0,
	'form submission: edit': 0,
	'form submission: send sms': 0,
	'form sms template: read': 0,
	'form sms template: edit': 0,
	'form sms template: create': 0,
};

export function setPermissions({
	key,
	val,
}: {
	key: keyof typeof permissions;
	val: number;
}) {
	permissions[key] = val;
}

export let currentUser: { permissions: number[] };
export function setCurrentUser(val: { permissions: number[] }) {
	currentUser = val;
}
