import { lazy } from 'react';

import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';
import Hooks from 'views/Hooks';
import ViewHookData from 'views/Hooks/ViewHookData';
import ViewData from 'views/Hooks/ViewData';

const Dashboard = Loadable(lazy(() => import('views/dashboard')));

const Purchase = Loadable(lazy(() => import('views/purchase/PurchaseList')));
const PurchaseViewDetails = Loadable(
	lazy(() => import('views/purchase/ViewDetails'))
);

const UserList = Loadable(lazy(() => import('views/user/UserList')));
const RoleList = Loadable(lazy(() => import('views/user/RoleList')));

const Settings = Loadable(lazy(() => import('views/settings')));

const Notification = Loadable(
	lazy(() => import('views/notification/NotificationList'))
);
const EditNotification = Loadable(
	lazy(() => import('views/notification/EditNotification/index'))
);
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
	path: '/',
	element: (
		<AuthGuard>
			<MainLayout />
		</AuthGuard>
	),
	children: [
		{
			path: '/dashboard',
			element: <Dashboard />,
		},
		{
			path: '/purchase',
			element: <Purchase />,
		},
		{
			path: '/purchase/:id',
			element: <PurchaseViewDetails />,
		},
		{
			path: '/users',
			element: <UserList />,
		},
		{
			path: '/roles',
			element: <RoleList />,
		},
		{
			path: '/settings',
			element: <Settings />,
		},
		{
			path: '/notification',
			element: <Notification />,
		},
		{
			path: '/notification/:id',
			element: <EditNotification />,
		},
		{
			path: '/forms',
			element: <Hooks />,
		},
		{
			path: '/forms/:id',
			element: <ViewHookData />,
		},
		{
			path: '/forms/:hookId/:hookDataId',
			element: <ViewData />,
		},
	],
};

export default MainRoutes;
