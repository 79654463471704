// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, useMediaQuery } from '@mui/material';

// project imports
import LAYOUT_CONST from 'constant';
import useConfig from 'hooks/useConfig';
import LogoSection from '../LogoSection';
import SearchSection from './SearchSection';
// import MobileSection from './MobileSection';
import ProfileSection from './ProfileSection';
// import LocalizationSection from './LocalizationSection';
import MegaMenuSection from './MegaMenuSection';
import NotificationSection from './NotificationSection';

import { useDispatch, useSelector } from 'store';
import { openDrawer } from 'store/slices/menu';

// assets
import { IconMenu2 } from '@tabler/icons';

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = () => {
	const theme = useTheme();

	const dispatch = useDispatch();
	const { drawerOpen } = useSelector((state) => state.menu);

	const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
	const { layout } = useConfig();

	return (
		<>
			{/* logo & toggler button */}
			<Box
				sx={{
					width: 228,
					display: 'flex',
					[theme.breakpoints.down('md')]: {
						width: 'auto',
					},
					alignItems: 'center',
				}}
			>
				<Box
					component="span"
					sx={{
						display: 'flex',
						flexDirection: 'row',
						alignItems: 'center',
						flexGrow: 1,
					}}
				>
					<LogoSection />
				</Box>
				{(layout === LAYOUT_CONST.VERTICAL_LAYOUT ||
					(layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd)) && (
					<Avatar
						variant="rounded"
						sx={{
							marginRight: '15px',
							marginLeft: '5px',
							...theme.typography.commonAvatar,
							...theme.typography.mediumAvatar,
							overflow: 'hidden',
							transition: 'all .2s ease-in-out',
							background:
								theme.palette.mode === 'dark'
									? theme.palette.dark.main
									: theme.palette.secondary.light,
							color:
								theme.palette.mode === 'dark'
									? theme.palette.secondary.main
									: theme.palette.secondary.dark,
							'&:hover': {
								background:
									theme.palette.mode === 'dark'
										? theme.palette.secondary.main
										: theme.palette.secondary.dark,
								color:
									theme.palette.mode === 'dark'
										? theme.palette.secondary.light
										: theme.palette.secondary.light,
							},
						}}
						onClick={() => dispatch(openDrawer(!drawerOpen))}
						color="inherit"
					>
						<IconMenu2 stroke={1.5} size="20px" />
					</Avatar>
				)}
			</Box>
			{/* header search */}
			<SearchSection />
			<Box sx={{ flexGrow: 1 }} />

			{/* mega-menu */}
			<Box sx={{ display: { xs: 'none', sm: 'block' } }}>
				<MegaMenuSection />
			</Box>

			{/* live customization & localization */}
			{/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
				<LocalizationSection />
			</Box> */}

			{/* notification & profile */}
			<NotificationSection />
			<Box sx={{ marginLeft: '3px', marginRight: '3px' }}>
				<ProfileSection />
			</Box>

			{/* mobile header */}
			{/* <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
				<MobileSection />
			</Box> */}
		</>
	);
};

export default Header;
