import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
	Avatar,
	Box,
	Card,
	CardContent,
	Chip,
	ClickAwayListener,
	Divider,
	Grid,
	InputAdornment,
	List,
	ListItemButton,
	ListItemIcon,
	ListItemText,
	OutlinedInput,
	Paper,
	Popper,
	Stack,
	Switch,
	Typography,
} from '@mui/material';

// third-party
import { FormattedMessage } from 'react-intl';
// import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import MainCard from 'ui-component/cards/MainCard';
import Transitions from 'ui-component/extended/Transitions';
import UpgradePlanCard from './UpgradePlanCard';
import useAuth from 'hooks/useAuth';
// import User1 from 'assets/images/users/user-round.svg';

// assets
import { IconLogout, IconSearch, IconSettings, IconUser } from '@tabler/icons';
import useConfig from 'hooks/useConfig';
import PersonIcon from '@mui/icons-material/Person';

// ==============================|| PROFILE MENU ||============================== //

const ProfileSection = () => {
	const theme = useTheme();
	const { borderRadius } = useConfig();
	const navigate = useNavigate();

	const [sdm, setSdm] = useState(true);
	const [value, setValue] = useState('');
	const [notification, setNotification] = useState(false);
	const [selectedIndex, setSelectedIndex] = useState(-1);
	const { logout, user } = useAuth();
	const [open, setOpen] = useState(false);
	/**
	 * anchorRef is used on different components and specifying one type leads to other components throwing an error
	 * */
	const anchorRef = useRef<any>(null);
	const handleLogout = async () => {
		try {
			await logout();
		} catch (err) {
			console.error(err);
		}
	};
	const handleListItemClick = (
		event: React.MouseEvent<HTMLDivElement>,
		index: number,
		// eslint-disable-next-line @typescript-eslint/no-inferrable-types
		route: string = ''
	) => {
		setSelectedIndex(index);
		handleClose(event);

		if (route && route !== '') {
			navigate(route);
		}
	};
	const handleToggle = () => {
		setOpen((prevOpen) => !prevOpen);
	};
	const handleClose = (
		event: React.MouseEvent<HTMLDivElement> | MouseEvent | TouchEvent
	) => {
		if (anchorRef.current && anchorRef.current.contains(event.target)) {
			return;
		}

		setOpen(false);
	};
	const prevOpen = useRef(open);
	useEffect(() => {
		if (prevOpen.current === true && open === false) {
			anchorRef.current.focus();
		}

		prevOpen.current = open;
	}, [open]);

	return (
		<>
			{/* <Chip
				sx={{
					height: '48px',
					alignItems: 'center',
					borderRadius: '27px',
					transition: 'all .2s ease-in-out',
					borderColor:
						theme.palette.mode === 'dark'
							? theme.palette.dark.main
							: theme.palette.primary.light,
					backgroundColor:
						theme.palette.mode === 'dark'
							? theme.palette.dark.main
							: theme.palette.primary.light,
					'&[aria-controls="menu-list-grow"], &:hover': {
						borderColor: theme.palette.primary.main,
						background: `${theme.palette.primary.main}!important`,
						color: theme.palette.primary.light,
						'& svg': {
							stroke: theme.palette.primary.light,
						},
					},
					'& .MuiChip-label': {
						lineHeight: 0,
					},
					marginLeft: '10px',
				}}
				icon={
					<Avatar
						src={User1}
						sx={{
							...theme.typography.mediumAvatar,
							margin: '8px 0 8px 8px !important',
							cursor: 'pointer',
							display: 'none',
						}}
						ref={anchorRef}
						aria-controls={open ? 'menu-list-grow' : undefined}
						aria-haspopup="true"
						color="inherit"
					/>
				}
				label={
					<IconSettings
						stroke={1.5}
						size="24px"
						color={theme.palette.primary.main}
					/>
				}
				variant="outlined"
				ref={anchorRef}
				aria-controls={open ? 'menu-list-grow' : undefined}
				aria-haspopup="true"
				onClick={handleToggle}
				color="primary"
			/> */}

			<Avatar
				variant="square"
				sx={{
					...theme.typography.commonAvatar,
					...theme.typography.mediumAvatar,
					border: '1px solid',
					borderColor:
						theme.palette.mode === 'dark'
							? theme.palette.dark.main
							: theme.palette.primary.light,
					background:
						theme.palette.mode === 'dark'
							? theme.palette.dark.main
							: theme.palette.primary.light,
					color: theme.palette.primary.dark,
					transition: 'all .2s ease-in-out',
					'&[aria-controls="menu-list-grow"],&:hover': {
						borderColor: theme.palette.primary.main,
						background: theme.palette.primary.main,
						color: theme.palette.primary.light,
					},
				}}
				ref={anchorRef}
				aria-controls={open ? 'menu-list-grow' : undefined}
				aria-haspopup="true"
				onClick={handleToggle}
				color="inherit"
			>
				<PersonIcon />
			</Avatar>

			<Popper
				placement="bottom"
				open={open}
				anchorEl={anchorRef.current}
				role={undefined}
				transition
				disablePortal
				modifiers={[
					{
						name: 'offset',
						options: {
							offset: [0, 14],
						},
					},
				]}
			>
				{({ TransitionProps }) => (
					<ClickAwayListener onClickAway={handleClose}>
						<Transitions in={open} {...TransitionProps}>
							<Paper>
								{open && (
									<MainCard
										border={false}
										elevation={16}
										content={false}
										boxShadow
										shadow={theme.shadows[16]}
									>
										<Box sx={{ p: 2, pb: 0 }}>
											<Stack>
												<Stack
													direction="column"
													spacing={0.5}
													alignItems="flex-start"
												>
													<Typography variant="h4">Hello,</Typography>
													<Typography
														component="span"
														variant="h4"
														sx={{ fontWeight: 400 }}
													>
														{user?.name}
													</Typography>
												</Stack>
											</Stack>
											<Divider />
											<OutlinedInput
												sx={{
													width: '100%',
													pr: 1,
													pl: 2,
													my: 2,
													display: 'none',
												}}
												id="input-search-profile"
												value={value}
												onChange={(e) => setValue(e.target.value)}
												placeholder="Search profile options"
												startAdornment={
													<InputAdornment position="start">
														<IconSearch
															stroke={1.5}
															size="16px"
															color={theme.palette.grey[500]}
														/>
													</InputAdornment>
												}
												aria-describedby="search-helper-text"
												inputProps={{
													'aria-label': 'weight',
												}}
											/>
											<Divider sx={{ display: 'none' }} />
										</Box>
										<Box
											style={{
												height: '100%',
												maxHeight: 'calc(100vh - 250px)',
												overflowX: 'hidden',
											}}
										>
											<Box sx={{ p: 2, pt: 0 }}>
												<Box sx={{ display: 'none' }}>
													<UpgradePlanCard />
													<Divider />
													<Card
														sx={{
															bgcolor:
																theme.palette.mode === 'dark'
																	? theme.palette.dark[800]
																	: theme.palette.primary.light,
															my: 2,
														}}
													>
														<CardContent>
															<Grid container spacing={3} direction="column">
																<Grid item>
																	<Grid
																		item
																		container
																		alignItems="center"
																		justifyContent="space-between"
																	>
																		<Grid item>
																			<Typography variant="subtitle1">
																				Start DND Mode
																			</Typography>
																		</Grid>
																		<Grid item>
																			<Switch
																				color="primary"
																				checked={sdm}
																				onChange={(e) =>
																					setSdm(e.target.checked)
																				}
																				name="sdm"
																				size="small"
																			/>
																		</Grid>
																	</Grid>
																</Grid>
																<Grid item>
																	<Grid
																		item
																		container
																		alignItems="center"
																		justifyContent="space-between"
																	>
																		<Grid item>
																			<Typography variant="subtitle1">
																				Allow Notifications
																			</Typography>
																		</Grid>
																		<Grid item>
																			<Switch
																				checked={notification}
																				onChange={(e) =>
																					setNotification(e.target.checked)
																				}
																				name="sdm"
																				size="small"
																			/>
																		</Grid>
																	</Grid>
																</Grid>
															</Grid>
														</CardContent>
													</Card>
													<Divider />
												</Box>
												<List
													component="nav"
													sx={{
														width: '100%',
														maxWidth: 350,
														minWidth: 300,
														backgroundColor: theme.palette.background.paper,
														borderRadius: '10px',
														[theme.breakpoints.down('md')]: {
															minWidth: '100%',
														},
														'& .MuiListItemButton-root': {
															mt: 0.5,
														},
													}}
												>
													<ListItemButton
														sx={{
															borderRadius: `${borderRadius}px`,
															display: 'none',
														}}
														selected={selectedIndex === 0}
														onClick={(
															event: React.MouseEvent<HTMLDivElement>
														) =>
															handleListItemClick(
																event,
																0,
																'/user/account-profile/profile1'
															)
														}
													>
														<ListItemIcon>
															<IconSettings stroke={1.5} size="20px" />
														</ListItemIcon>
														<ListItemText
															primary={
																<Typography variant="body2">
																	<FormattedMessage id="account-settings" />
																</Typography>
															}
														/>
													</ListItemButton>
													<ListItemButton
														sx={{
															borderRadius: `${borderRadius}px`,
															display: 'none',
														}}
														selected={selectedIndex === 1}
														onClick={(
															event: React.MouseEvent<HTMLDivElement>
														) =>
															handleListItemClick(
																event,
																1,
																'/user/social-profile/posts'
															)
														}
													>
														<ListItemIcon>
															<IconUser stroke={1.5} size="20px" />
														</ListItemIcon>
														<ListItemText
															primary={
																<Grid
																	container
																	spacing={1}
																	justifyContent="space-between"
																>
																	<Grid item>
																		<Typography variant="body2">
																			<FormattedMessage id="social-profile" />
																		</Typography>
																	</Grid>
																	<Grid item>
																		<Chip
																			label="02"
																			size="small"
																			sx={{
																				bgcolor:
																					theme.palette.mode === 'dark'
																						? theme.palette.dark.dark
																						: theme.palette.warning.dark,
																				color: theme.palette.background.default,
																			}}
																		/>
																	</Grid>
																</Grid>
															}
														/>
													</ListItemButton>
													<ListItemButton
														sx={{ borderRadius: `${borderRadius}px` }}
														selected={selectedIndex === 4}
														onClick={handleLogout}
													>
														<ListItemIcon>
															<IconLogout stroke={1.5} size="20px" />
														</ListItemIcon>
														<ListItemText
															primary={
																<Typography variant="body2">
																	<FormattedMessage id="logout" />
																</Typography>
															}
														/>
													</ListItemButton>
												</List>
											</Box>
										</Box>
									</MainCard>
								)}
							</Paper>
						</Transitions>
					</ClickAwayListener>
				)}
			</Popper>
		</>
	);
};

export default ProfileSection;
