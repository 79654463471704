import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

// material-ui
import {
	CardContent,
	Grid,
	Typography,
	Stack,
	IconButton,
	Box,
} from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import MainCard from 'ui-component/cards/MainCard';

import { useDispatch, useSelector } from 'store';
import Loader from 'ui-component/Loader';
import _l from 'utils/lang';
import { formApi } from 'store/slices/form';
import { Form, FormData } from 'types/form';
import { CommentSection } from 'ui-component/costume/Comment';
import { TGetMessage } from 'types/messages';
import { getAllMessages } from 'store/slices/message';
import { printDate } from 'utils/helpers/printDate';
import Chip from 'ui-component/extended/Chip';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import SetApprovals from './setApprovals';
import SmsSender from 'ui-component/costume/SmsSender';
import { SendSms } from 'types/sendSms';
import { sendSmsApi } from 'store/slices/sendSms';
import ButtonConfirm from 'ui-component/costume/ButtonConfirm';
import { currentUser, permissions } from 'global';
import { TGetUser } from 'types/user';

// animation
interface TextProps {
	label: string;
	value: string | number | undefined;
}
const Text = ({ label, value }: TextProps) => (
	<Stack direction="row" spacing={1}>
		<Typography variant="subtitle1">{label} :</Typography>
		<Typography variant="body2" display="flex" alignItems="center">
			{typeof value === 'object' ? JSON.stringify(value) : value}
		</Typography>
	</Stack>
);

const ButtonBack = () => {
	const navigate = useNavigate();
	return (
		<Box>
			<IconButton color="secondary" size="small" onClick={() => navigate(-1)}>
				<ChevronLeftIcon sx={{ fontSize: '1.3rem' }} />
			</IconButton>
		</Box>
	);
};

const STATUS = {
	1: 'in process',
	2: 'approved',
	3: 'rejected',
};

const STATUS_COLOR = {
	1: 'primary',
	2: 'success',
	3: 'error',
};

interface Params {
	hookId: string;
	hookDataId: string;
}
export default function ViewData() {
	const navigate = useNavigate();
	const params = useParams() as Readonly<Params>;
	const dispatch = useDispatch();
	const [isLoading, setLoading] = React.useState(true);
	const { form, formDataById, approvals } = useSelector((state) => state.form);
	const [formApprovals, setFormApprovals] = React.useState<any[]>([]);
	const [thisForm, setThisHook] = React.useState<Form | undefined>(undefined);
	const [hookDataDetails, setHookDataDetails] = React.useState<
		{ key: string; value: string }[]
	>([]);
	const [msg, setMsg] = React.useState<TGetMessage[]>([]);
	const [sendedSms, setSms] = React.useState<SendSms[]>([]);
	const { messages } = useSelector((state) => state.message);
	const { sms } = useSelector((state) => state.sendSms);
	const { messagesReceivers } = useSelector((state) => state.form);
	const [openEdit, setOpenEdit] = React.useState(false);
	const [receivers, setReceivers] = React.useState<TGetUser[]>([]);

	React.useEffect(() => {
		if (!currentUser.permissions.includes(permissions['form submission: read']))
			navigate('/forms');
		dispatch(formApi.getFormDataById(params.hookDataId));
		dispatch(formApi.getForm(params.hookId));
		dispatch(formApi.clearFormApprovalsState());
	}, [dispatch]);
	React.useEffect(() => {
		setThisHook(form);
	}, [form]);
	React.useEffect(() => {
		setMsg(messages);
	}, [messages]);
	React.useEffect(() => {
		setSms(sms);
	}, [sms]);
	React.useEffect(() => {
		setReceivers(messagesReceivers);
	}, [messagesReceivers]);
	React.useEffect(() => {
		if (formDataById && formDataById?.id === Number(params.hookDataId)) {
			dispatch(getAllMessages(formDataById.uuid));
			dispatch(sendSmsApi.getAll(formDataById.uuid));
			dispatch(formApi.getFormApprovals(formDataById.uuid));
			dispatch(formApi.getReceivers(formDataById.uuid));

			const list: { key: string; value: string }[] = [];

			const keys = Object.keys(formDataById.data);
			for (const key of keys)
				list.push({ key, value: formDataById?.data[key] as string });

			setHookDataDetails(list);

			if (keys.length === 0) setLoading(false);
		}
	}, [formDataById]);
	React.useEffect(() => {
		if (
			formDataById?.id === Number(params.hookDataId) &&
			hookDataDetails.length > 0
		)
			setLoading(false);
	}, [hookDataDetails]);
	React.useEffect(() => {
		if (approvals.length > 0 && formDataById?.id === Number(params.hookDataId))
			setFormApprovals(approvals);
	}, [approvals]);

	const purchaseConfirm = (status: number) => async () => {
		const userId = approvals.find(
			(approval) => approval.User.email === localStorage.getItem('email')
		)?.userId;
		if (userId) {
			await formApi.approvalConfirm({
				status,
				userId,
				ref: formDataById!.uuid,
			});
			dispatch(formApi.getFormApprovals(formDataById!.uuid));
			dispatch(formApi.getFormDataById(params.hookDataId));
		}
	};

	return (
		<div>
			{isLoading ? (
				<Loader />
			) : (
				<Grid container spacing={2}>
					<Grid item xs={12}>
						<MainCard
							// @ts-ignore
							title={<Title hook={thisForm} formData={formDataById} />}
							content={true}
							sx={{ marginTop: '10px' }}
						>
							<CardContent sx={{ padding: '0px' }}>
								<Grid container spacing={1}>
									{hookDataDetails.map((data) => (
										<Grid item key={data.key} xs={12}>
											<Text label={data.key} value={data.value} />
										</Grid>
									))}
								</Grid>
							</CardContent>
						</MainCard>
					</Grid>

					<Grid item xs={12}>
						<MainCard
							title={
								<TitleApprovals
									onClickEdit={() => setOpenEdit(true)}
									onClickConfirm={purchaseConfirm}
									form={thisForm}
									formData={formDataById}
									status={
										approvals.find(
											(approval) =>
												approval.User.email === localStorage.getItem('email')
										)?.status || undefined
									}
								/>
							}
							content={false}
						>
							<CardContent>
								<Grid container spacing={1}>
									<Grid item xs={12}>
										{formApprovals.length > 0 &&
											formApprovals.map((approval) => (
												<Stack
													direction="row"
													key={approval.User.email}
													spacing={1}
													sx={{
														display: 'flex',
														flexDirection: 'row',
														justifyContent: 'flex-start',
														alignItems: 'center',
													}}
												>
													<Typography variant="subtitle1">
														{`${approval.User.firstName} ${approval.User.lastName}`}
													</Typography>
													<Chip
														label={
															STATUS[approval.status as keyof typeof STATUS]
														}
														size="small"
														chipcolor={
															STATUS_COLOR[
																approval.status as keyof typeof STATUS_COLOR
															]
														}
													/>
													{approval.status !== 1 && (
														<Chip
															label={printDate(approval.updatedAt)}
															size="small"
															chipcolor="primary"
														/>
													)}
												</Stack>
											))}
									</Grid>
								</Grid>
							</CardContent>
						</MainCard>
					</Grid>

					{formDataById &&
						formDataById.id === Number(params.hookDataId) &&
						formDataById.phone &&
						currentUser.permissions.includes(
							permissions['form submission: send sms']
						) && (
							<Grid item xs={12}>
								<SmsSender
									messages={sendedSms}
									target={window.location.pathname}
									reference={formDataById.uuid}
									receiver={formDataById.phone}
								/>
							</Grid>
						)}

					{formDataById && formDataById.id === Number(params.hookDataId) && (
						<Grid item xs={12}>
							<CommentSection
								messages={msg}
								target={window.location.pathname}
								sectionId={10}
								uuid={formDataById.uuid}
								users={receivers}
							/>
						</Grid>
					)}

					{openEdit && (
						<SetApprovals
							handleCloseDialog={() => setOpenEdit(false)}
							formDataRef={formDataById!.uuid}
							formDataId={String(formDataById!.id)}
						/>
					)}
				</Grid>
			)}
		</div>
	);
}

interface TitleProps {
	hook: Form | undefined;
	formData: FormData;
}
function Title(props: TitleProps) {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
			}}
		>
			<Box
				width="100%"
				sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
			>
				<ButtonBack />
				{props.hook?.description} #{props.hook?.id} #{props.formData.id}
				<Chip
					sx={{ ml: 1 }}
					label={STATUS[props.formData.status as keyof typeof STATUS]}
					size="small"
					chipcolor={
						STATUS_COLOR[props.formData.status as keyof typeof STATUS_COLOR]
					}
				/>
			</Box>
		</Box>
	);
}

interface TitleApprovalProps {
	onClickEdit: () => void;
	onClickConfirm: (status: number) => () => Promise<void>;
	status: number | undefined;
	form: Form | undefined;
	formData: Omit<FormData, 'row_number'> | undefined;
}
function TitleApprovals(props: TitleApprovalProps) {
	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'row',
				alignItems: 'center',
				justifyContent: 'space-between',
			}}
		>
			<Box
				width="100%"
				sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
			>
				{_l('approvals')}
			</Box>
			<Box sx={{ display: 'flex' }}>
				{props.formData?.status !== 3 &&
					props.form?.User?.email === localStorage.getItem('email') && (
						<IconButton
							color="secondary"
							size="small"
							onClick={props.onClickEdit}
						>
							<EditTwoToneIcon sx={{ fontSize: '1.3rem' }} />
						</IconButton>
					)}
				{props.status && props.status === 1 && (
					<>
						<ButtonConfirm
							id={1}
							color="success"
							label={_l('accept')}
							callback={props.onClickConfirm(2)}
							sx={{ ml: 1 }}
						/>
						<ButtonConfirm
							id={1}
							color="error"
							label={_l('reject')}
							callback={props.onClickConfirm(3)}
							sx={{ ml: 0.5 }}
						/>
					</>
				)}
			</Box>
		</Box>
	);
}
