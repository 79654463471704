export function printDate(date: Date | null | undefined, def?: string) {
	if (!date) return def || '';
	const d = new Date(date);
	return `${beautifully(d.getDate())}.${beautifully(
		d.getMonth() + 1
	)}.${beautifully(d.getFullYear())} ${beautifully(d.getHours())}:${beautifully(
		d.getMinutes()
	)}`;
}

function beautifully(val: number) {
	return val < 10 ? '0' + val : val;
}
