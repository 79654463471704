// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/live-axios';
import { dispatch } from '../index';

// types
import { DefaultRootStateProps } from 'types';
import { TSetCurrency } from 'types/currency';
import { alert } from 'utils/helpers/alert';
import _l from 'utils/lang';

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['currency'] = {
	error: null,
	currencies: [],
	activeCurrencies: [],
};

const slice = createSlice({
	name: 'currency',
	initialState,
	reducers: {
		hasError(state, action) {
			state.error = action.payload;
		},

		getCurrenciesSuccess(state, action) {
			state.currencies = action.payload;
		},

		getActiveCurrenciesSuccess(state, action) {
			state.activeCurrencies = action.payload;
		},
	},
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export const currencyApi = {
	getAll: (params?: { status: 'active' | 'inactive' }) => async () => {
		try {
			const response = await axios.get('/currency', { params });
			if (params?.status === 'active')
				dispatch(slice.actions.getActiveCurrenciesSuccess(response.data));
			else dispatch(slice.actions.getCurrenciesSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	},
	get create() {
		return async (data: TSetCurrency, options?: { sync: boolean }) => {
			try {
				await axios.post('/currency', data);
				if (options?.sync === true) this.getAll()();
				alert.display(_l('currency-created-successfully'));
			} catch (error) {
				if (options?.sync === true) dispatch(slice.actions.hasError(error));
			}
		};
	},
	get setMainCurrency() {
		return async (id: number, options?: { sync: boolean }) => {
			try {
				await axios.put(`/currency/main/${id}`);
				if (options?.sync === true) this.getAll()();
				alert.display(_l('main-currency-changed-successfully'));
			} catch (error) {
				if (options?.sync === true) dispatch(slice.actions.hasError(error));
			}
		};
	},
	get update() {
		return async (
			id: number,
			data: TSetCurrency,
			options?: { sync: boolean }
		) => {
			try {
				await axios.put(`/currency/${id}`, data);
				if (options?.sync === true) this.getAll()();
				alert.display(_l('currency-updated-successfully'));
			} catch (error) {
				if (options?.sync === true) dispatch(slice.actions.hasError(error));
			}
		};
	},
};
