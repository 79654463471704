import * as React from 'react';
import {
	CardContent,
	Grid,
	Typography,
	TextField,
	IconButton,
	Button,
	MenuItem,
	Card,
	Menu,
	Chip,
	Box,
} from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
// import { useDispatch } from 'store';
// import { getAllMessages, updateMessage } from 'store/slices/message';

interface CommentHistoryProps {
	username: string;
	time: string;
	text: string;
	messageId: number;
	userEmail: string;
	target: string;
	sectionId: number;
	uuid: string;
	edited?: boolean;
	editable?: boolean;
	textBottom?: boolean;
}
export function SmsHistory(props: CommentHistoryProps) {
	const editable = props.editable ?? true;

	// const dispatch = useDispatch();
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const [edit, setEdit] = React.useState(false);
	const [message, setMessage] = React.useState('');
	const open = Boolean(anchorEl);
	const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleCloseMenu = () => {
		setAnchorEl(null);
	};

	const handleEdit = () => {
		setMessage(props.text);
		setEdit(true);
		handleCloseMenu();
	};
	const handleCancelEdit = () => {
		setEdit(false);
	};

	const handleSave = async () => {
		// await updateMessage(props.messageId, { text: message });
		// dispatch(getAllMessages(props.uuid));
		setEdit(false);
	};

	return (
		<Grid
			container
			spacing={1}
			sx={{
				borderStyle: 'solid',
				borderColor: '#E4E5E4',
				borderRadius: 1,
			}}
		>
			<Grid
				item
				xs={11}
				sx={{
					display: 'flex',
					flexDirection: 'row',
					paddingTop: '4px !important',
					alignItems: 'center',
				}}
			>
				<Typography variant="h5" sx={{ cursor: 'default' }}>
					{props.username}
				</Typography>
				<Typography
					variant="subtitle2"
					sx={{ marginLeft: '15px', cursor: 'default' }}
				>
					{props.time}
				</Typography>
				{props.edited && (
					<Chip label="edited" size="small" sx={{ marginLeft: '5px' }} />
				)}
			</Grid>
			{props.userEmail === localStorage.getItem('email') && editable ? (
				<Grid
					item
					xs={1}
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'flex-end',
						alignItems: 'center',
						paddingTop: '4px !important',
						paddingRight: '4px !important',
					}}
				>
					{edit ? (
						<>
							<Button onClick={handleCancelEdit} color="inherit">
								Cancel
							</Button>
							<Button
								onClick={handleSave}
								variant="contained"
								color="primary"
								disabled={message == props.text || message.length === 0}
							>
								Save
							</Button>
						</>
					) : (
						<>
							<IconButton
								id="icon-button-menu"
								aria-controls={open ? 'message-menu' : undefined}
								aria-haspopup="true"
								aria-expanded={open ? 'true' : undefined}
								onClick={handleOpenMenu}
							>
								<MoreVertIcon />
							</IconButton>
							<Menu
								id="message-menu"
								anchorEl={anchorEl}
								open={open}
								onClose={handleCloseMenu}
								MenuListProps={{
									'aria-labelledby': 'icon-button-menu',
								}}
							>
								<MenuItem onClick={handleEdit} sx={{ minWidth: '150px' }}>
									Edit
								</MenuItem>
							</Menu>
						</>
					)}
				</Grid>
			) : (
				<Grid item xs={1}></Grid>
			)}
			<Grid item xs={12} sx={{ paddingTop: '0px !important' }}>
				<Card
					sx={{
						display: 'flex',
						width: '100%',
						paddingTop: '0px',
					}}
				>
					<CardContent
						sx={{
							p: 1,
							pb: '4px !important',
							width: '100%',
							padding: '0px !important',
							paddingRight: '2px !important',
						}}
					>
						<Grid container spacing={0}>
							<Grid item xs={12}>
								{!edit ? (
									<>
										<Typography
											sx={{ width: '100%', whiteSpace: 'pre-line' }}
											variant="body2"
										>
											{props.text}
										</Typography>
										{props.textBottom && (
											<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
												{Math.ceil(props.text.length / 160) > 1
													? `sms: ${Math.ceil(props.text.length / 160)}`
													: ''}
											</Box>
										)}
									</>
								) : (
									<TextField
										id={`comment-${props.messageId}`}
										fullWidth
										multiline
										minRows={3}
										maxRows={15}
										value={message}
										onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
											setMessage(event?.target.value);
										}}
										sx={{ paddingTop: '4px' }}
									/>
								)}
							</Grid>
						</Grid>
					</CardContent>
				</Card>
			</Grid>
		</Grid>
	);
}
