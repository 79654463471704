// third-party
import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// project imports
import snackbarReducer from './slices/snackbar';
import notistackReducer from './slices/notistack';
import menuReducer from './slices/menu';
import cartReducer from './slices/cart';
import purchaseReducer from './slices/purchase';
import currencyReducer from './slices/currency';
import userReducer from './slices/user';
import roleReducer from './slices/role';
import actionReducer from './slices/action';
import notificationTriggerReducer from './slices/notificationTrigger';
import dynamicDataReducer from './slices/dynamicData';
import emailReducer from './slices/email';
import smsReducer from './slices/sms';
import sectionReducer from './slices/section';
import variableReducer from './slices/variable';
import messageReducer from './slices/message';
import formReducer from './slices/form';
import sendSmsReducer from './slices/sendSms';

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
	notistack: notistackReducer,
	snackbar: snackbarReducer,
	menu: menuReducer,
	cart: persistReducer(
		{
			key: 'cart',
			storage,
			keyPrefix: 'berry-',
		},
		cartReducer
	),
	purchase: purchaseReducer,
	currency: currencyReducer,
	user: userReducer,
	role: roleReducer,
	action: actionReducer,
	notificationTrigger: notificationTriggerReducer,
	dynamicData: dynamicDataReducer,
	email: emailReducer,
	sms: smsReducer,
	section: sectionReducer,
	variable: variableReducer,
	message: messageReducer,
	form: formReducer,
	sendSms: sendSmsReducer,
});

export default reducer;
