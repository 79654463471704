const environment = {
	dev: {
		firebase: {
			apiKey: 'AIzaSyBP_pL8IikYlRY28mPgNxOZ_trrnBwxWvk',
			authDomain: 'ekklesia-portal-dev.firebaseapp.com',
			projectId: 'ekklesia-portal-dev',
			storageBucket: 'ekklesia-portal-dev.appspot.com',
			messagingSenderId: '695108061831',
			appId: '1:695108061831:web:cd8d85673c58fb5d564622',
			measurementId: 'G-CV00JLX0ZK',
		},
		be_host: 'http://localhost:3001',
	},
	alpha: {
		firebase: {
			apiKey: 'AIzaSyBhcSD9j2oWgoMQN8WkhUj-rlX_-Q1Dzxk',
			authDomain: 'ekklesia-portal-alpha.firebaseapp.com',
			projectId: 'ekklesia-portal-alpha',
			storageBucket: 'ekklesia-portal-alpha.appspot.com',
			messagingSenderId: '166805113545',
			appId: '1:166805113545:web:969b7f098b9aea9a9d9b18',
			measurementId: 'G-Q4HZB9WW31',
		},
		be_host: 'https://alpha.api.ekklesia.ro',
	},
	production: {
		firebase: {
			apiKey: 'AIzaSyCIwg1UqGzloFaaU3DSLI00meSpuafv1PE',
			authDomain: 'ekklesia-portal.firebaseapp.com',
			projectId: 'ekklesia-portal',
			storageBucket: 'ekklesia-portal.appspot.com',
			messagingSenderId: '485378297900',
			appId: '1:485378297900:web:4630211f52f1b69920c189',
			measurementId: 'G-XPJVZE0RKL',
		},
		be_host: 'https://api.ekklesia.ro',
	},
};

export const ENV: 'dev' | 'alpha' | 'production' = 'alpha';
export const FIREBASE = environment[ENV].firebase;
export const GOOGLE_ANALYTICS_ID = environment[ENV].firebase.measurementId;
export const BACKEND_HOST = environment[ENV].be_host;
