import en from './locales/en.json';
import ro from './locales/ro.json';

const languages = {
	en,
	ro,
};

export default function _l(id: keyof typeof en | string) {
	const { locale } = <
		{
			locale: 'en' | 'ro';
		}
	>JSON.parse(localStorage.getItem('berry-config-ts')!) || { locale: 'en' };

	// @ts-ignore
	return languages[locale || 'en'][id] || '_l(' + id + ')';
}
