import * as React from 'react';
import {
	CardContent,
	Grid,
	TextField,
	Button,
	useMediaQuery,
	Box,
	MenuItem,
	Menu,
	Divider,
} from '@mui/material';
import MainCard from 'ui-component/cards/MainCard';
import { useDispatch, useSelector } from 'store';
import _l from 'utils/lang';
import { SendSms } from 'types/sendSms';
import { sendSmsApi } from 'store/slices/sendSms';
import { SmsHistory } from './History';
import { printDate } from 'utils/helpers/printDate';
import { formApi } from 'store/slices/form';
import { FormSmsTemplate } from 'types/form';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

interface Props {
	messages: SendSms[];
	target: string;
	reference: string;
	receiver: string;
}
export default function SmsSender(props: Props) {
	const dispatch = useDispatch();
	const [messageText, setMessageText] = React.useState('');
	const [templates, setTemplates] = React.useState<FormSmsTemplate[]>([]);
	const { formSmsTemplates } = useSelector((state) => state.form);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);

	React.useEffect(() => {
		dispatch(formApi.getFormSmsTemplates());
	}, []);
	React.useEffect(() => {
		setTemplates(formSmsTemplates);
	}, [formSmsTemplates]);

	const handleSendMessage = async () => {
		await sendSmsApi.create(props.reference, {
			message: messageText,
			receiver: props.receiver,
		});
		setMessageText('');
		dispatch(sendSmsApi.getAll(props.reference));
	};
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const lgBreakpoint = useMediaQuery('(min-width:1024px)', { noSsr: true });

	return (
		<>
			<MainCard title={_l('send-sms')} content={false}>
				<CardContent>
					<Grid container spacing={1}>
						<Grid
							item
							xs={12}
							sx={{ marginBottom: 3, paddingLeft: '0px !important' }}
						>
							<Grid container spacing={1}>
								<Grid item xs={12}>
									<Button
										id="demo-positioned-button"
										aria-controls={open ? 'demo-positioned-menu' : undefined}
										aria-haspopup="true"
										aria-expanded={open ? 'true' : undefined}
										onClick={handleClick}
										variant="outlined"
										endIcon={<KeyboardArrowDownIcon />}
									>
										{_l('select-sms-template')}
									</Button>
									<Menu
										id="demo-positioned-menu"
										aria-labelledby="demo-positioned-button"
										anchorEl={anchorEl}
										open={open}
										onClose={handleClose}
										anchorOrigin={{
											vertical: 'bottom',
											horizontal: 'left',
										}}
										transformOrigin={{
											vertical: 'top',
											horizontal: 'left',
										}}
										sx={{ maxHeight: '300px' }}
									>
										{templates.map((template) => (
											<>
												<MenuItem
													key={String(template.id)}
													onClick={() => {
														setMessageText(template.message);
														handleClose();
													}}
												>
													{template.title}
												</MenuItem>
												<Divider
													sx={{
														marginTop: '2px !important',
														marginBottom: '2px !important',
													}}
												/>
											</>
										))}
									</Menu>
								</Grid>

								<Grid item xs={12}>
									<TextField
										id="comment-text"
										fullWidth
										multiline
										minRows={4}
										maxRows={12}
										placeholder={_l('type-message')}
										value={messageText}
										onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
											setMessageText(event?.target.value);
										}}
										inputProps={{
											style: {
												padding: lgBreakpoint ? '16px' : '6px',
											},
										}}
									/>
									<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
										{Math.ceil(messageText.length / 160) > 1
											? `sms: ${Math.ceil(messageText.length / 160)}`
											: ''}{' '}
										{messageText.length % 160 || 160}/160
									</Box>
								</Grid>
								<Grid
									item
									xs={2}
									sx={{
										display: 'flex',
										justifyContent: 'flex-start',
										alignItems: 'center',
									}}
								>
									<Button
										variant="contained"
										onClick={handleSendMessage}
										disabled={!messageText}
									>
										{_l('send')}
									</Button>
								</Grid>
							</Grid>
						</Grid>

						{props.messages.map((message) => (
							<Grid
								key={message.id}
								item
								xs={12}
								sx={{
									paddingTop: '16px !important',
								}}
							>
								<SmsHistory
									username={
										message.sender
											? message.sender.firstName + ' ' + message.sender.lastName
											: 'System'
									}
									time={printDate(message.createdAt)}
									text={message.message}
									messageId={message.id}
									userEmail={message?.sender?.email || ''}
									target={props.target}
									sectionId={9}
									uuid={props.reference}
									editable={false}
									textBottom={true}
								/>
							</Grid>
						))}
					</Grid>
				</CardContent>
			</MainCard>
		</>
	);
}
