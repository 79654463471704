// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import axios from 'utils/live-axios';
import { dispatch } from '../index';

// types
import { DefaultRootStateProps } from 'types';
import { alert } from 'utils/helpers/alert';
import _l from 'utils/lang';
import { SetForm, SetFormSmsTemplate } from 'types/form';

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps['form'] = {
	error: null,
	form: undefined,
	formData: [],
	formDataById: undefined,
	forms: [],
	approvals: [],
	formSmsTemplates: [],
	messagesReceivers: [],
};

const slice = createSlice({
	name: 'form',
	initialState,
	reducers: {
		hasError(state, action) {
			state.error = action.payload;
		},

		getFormSuccess(state, action) {
			state.form = action.payload;
		},

		getFormsSuccess(state, action) {
			state.forms = action.payload;
		},

		getFormDataSuccess(state, action) {
			state.formData = action.payload;
		},

		getFormDataByIdSuccess(state, action) {
			state.formDataById = action.payload;
		},

		getFormApprovalsSuccess(state, action) {
			state.approvals = action.payload;
		},

		getFormSmsTemplatesSuccess(state, action) {
			state.formSmsTemplates = action.payload;
		},

		getMessageReceiversSuccess(state, action) {
			state.messagesReceivers = action.payload;
		},
	},
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export const formApi = {
	getForms: () => async () => {
		try {
			const response = await axios.get('/form');
			dispatch(slice.actions.getFormsSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	},
	getReceivers: (ref: string) => async () => {
		try {
			const response = await axios.get('/form/receivers/' + ref);
			dispatch(slice.actions.getMessageReceiversSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	},
	getForm: (id: string) => async () => {
		try {
			const response = await axios.get(`/form/${id}`);
			dispatch(slice.actions.getFormSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	},
	getFormData: (id: string, statuses: (number | string)[]) => async () => {
		try {
			const response = await axios.get(`/form/${id}/data`, {
				params: { statuses },
			});
			dispatch(slice.actions.getFormDataSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	},
	getFormDataById: (id: string) => async () => {
		try {
			const response = await axios.get(`/form/data/${id}`);
			dispatch(slice.actions.getFormDataByIdSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	},
	getFormApprovals: (ref: string) => async () => {
		try {
			const response = await axios.get(`/form/approval/${ref}`);
			dispatch(slice.actions.getFormApprovalsSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	},
	getFormSmsTemplates: () => async () => {
		try {
			const response = await axios.get(`/form/template`);
			dispatch(slice.actions.getFormSmsTemplatesSuccess(response.data));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	},
	clearFormApprovalsState: () => async () => {
		try {
			dispatch(slice.actions.getFormApprovalsSuccess([]));
		} catch (error) {
			dispatch(slice.actions.hasError(error));
		}
	},
	get update() {
		return async (id: number, data: SetForm) => {
			try {
				await axios.put(`/form/${id}`, data);
				alert.display(_l('hook-updated-successfully'));
			} catch (error) {
				dispatch(slice.actions.hasError(error));
			}
		};
	},
	get updateMessage() {
		return async (id: number, message: string) => {
			try {
				await axios.put(`/form/${id}/message`, { message });
				alert.display(_l('hook-updated-successfully'));
			} catch (error) {
				dispatch(slice.actions.hasError(error));
			}
		};
	},
	get updateFormSmsTemplate() {
		return async (id: number, data: SetFormSmsTemplate) => {
			try {
				await axios.put(`/form/${id}/template`, data);
				alert.display(_l('sms-template-updated-successfully'));
			} catch (error) {
				dispatch(slice.actions.hasError(error));
			}
		};
	},
	get create() {
		return async (data: SetForm) => {
			try {
				await axios.post(`/form`, data);
				alert.display(_l('hook-created-successfully'));
			} catch (error) {
				dispatch(slice.actions.hasError(error));
			}
		};
	},
	get createFormSmsTemplate() {
		return async (data: SetFormSmsTemplate) => {
			try {
				await axios.post(`/form/template`, data);
				alert.display(_l('sms-template-created-successfully'));
			} catch (error) {
				dispatch(slice.actions.hasError(error));
			}
		};
	},
	get setApprovals() {
		return async (data: { ref: string; approvals: number[] }) => {
			try {
				await axios.post(`/form/approval`, data);
				alert.display(_l('hook-updated-successfully'));
			} catch (error) {
				dispatch(slice.actions.hasError(error));
			}
		};
	},
	get approvalConfirm() {
		return async (data: { ref: string; status: number; userId: number }) => {
			try {
				await axios.post(`/form/approval/confirm`, data);
				alert.display(_l('hook-updated-successfully'));
			} catch (error) {
				dispatch(slice.actions.hasError(error));
			}
		};
	},
};
